import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  textsContainerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: isMobile() ? theme.defaultMargin : 0,
    paddingRight: isMobile() ? theme.defaultMargin : 0,
    zIndex: 10,
  },
  weAreTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 38 : 70),
    color: "white",
    zIndex: 1,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
  },
  pixxerTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 35 : 85),
    color: theme.magenta,
    zIndex: 1,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
  },

  payloadTextStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(21),
    fontWeight: "bold",
    color: "white",
    letterSpacing: isMobile() ? 0 : 2.2,
    marginTop: 16,
    zIndex: 1,
  },
  payloadSmallTextStyle: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(20),
    marginTop: 16,
    width: isMobile() ? "100%" : "60%",
    textAlign: "center",
  },
  payloadSmallEvidenceTextStyle: { color: theme.lightBlue },
  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 250,
    height: 250,
    borderRadius: 135,
    position: "absolute",
    top: 20,
    left: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 400,
    height: 400,
    borderRadius: 200,
    position: "absolute",
    top: 400,
    right: -50,
  },
};

export default styles;

import React, { Component, Fragment } from "react";
import styles from "./styles";

class AnimatedCircles extends Component {
  render() {
    const { bigCircleStyle, smallCircleStyle } = styles;
    return (
      <Fragment>
        <div id="bigCircle" style={bigCircleStyle} />
        <div id="smallCircle" style={smallCircleStyle} />
      </Fragment>
    );
  }
}

export default AnimatedCircles;

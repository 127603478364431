import React, { Component, Fragment } from "react";
import { TweenLite } from "gsap";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import _ from "lodash";
import styles from "./styles";
import { isMobile } from "../../utils";
import * as mouseActions from "./../../reducers/mouse/actions";
import { animateMouse } from "../../animationUtils";

class AnimatedLink extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.childRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dimensions, elToAppend, icon } = this.props;
    const prevDimensions = prevProps.dimensions;
    const actualWidth = dimensions.width;
    const actualHeight = dimensions.height;
    const prevWidth = prevDimensions.width;
    const prevHeight = prevDimensions.height;
    if (prevWidth !== actualWidth || prevHeight !== actualHeight) {
      if (elToAppend === this.childRef.current) {
        const {
          children,
          style,
          iconPosition,
          iconWidth,
          iconMargin,
        } = this.props;
        const { textAndIconContainerStyle, elToRenderContainerStyle } = styles;
        let imgPosition = iconPosition ? iconPosition : "left";
        let imgWidth = iconWidth ? iconWidth : "auto";
        let imgMargin = iconMargin ? iconMargin : 15;
        const padding = 10;
        const cursor = document.getElementById("cursor1");
        this.containerRef.current.opacity = 0;
        TweenLite.to(this.childRef.current, 0.1, { opacity: 0 });
        let elToRender;
        if (
          icon &&
          _.isArray(children.props.children) &&
          children.props.children.length === 2
        ) {
          elToRender = (
            <div style={style}>
              <div style={textAndIconContainerStyle}>
                {imgPosition === "left" && (
                  <Fragment>
                    <img
                      alt="icon"
                      src={icon}
                      style={{ marginRight: imgMargin, width: imgWidth }}
                    />
                    {children.props.children[1]}
                  </Fragment>
                )}
                {imgPosition === "right" && (
                  <Fragment>
                    {children.props.children[0]}
                    <img
                      alt="icon"
                      src={icon}
                      style={{ marginLeft: imgMargin, width: imgWidth }}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          );
        } else {
          elToRender = <div style={style}>{children}</div>;
        }
        ReactDOM.render(
          <div style={elToRenderContainerStyle}>{elToRender}</div>,
          cursor
        );

        animateMouse(actualHeight, padding);
      }
    }
  }

  handleMouseLeave = () => {
    const { setIsOnLink } = this.props;
    if (isMobile()) {
      return;
    }
    this.containerRef.current.opacity = 1;
    setIsOnLink();
    TweenLite.to(this.childRef.current, 0.1, { opacity: 1 });
    ReactDOM.render("", document.getElementById("cursor1"));
    animateMouse();
  };
  handleMouseEnter = () => {
    if (isMobile()) {
      return;
    }
    const { setIsOnLink } = this.props;
    let elWidth = this.childRef.current.offsetWidth;
    let elHeight = this.childRef.current.offsetHeight;
    setIsOnLink(elWidth, elHeight, this.childRef.current);
  };
  render() {
    const { style } = this.props;
    return (
      <div
        ref={this.containerRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={style}
      >
        <div ref={this.childRef}>{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ mouse }) => {
  return {
    dimensions: mouse.dimensions,
    isOnLink: mouse.isOnLink,
    elToAppend: mouse.elToAppend,
  };
};

export default connect(mapStateToProps, mouseActions)(AnimatedLink);

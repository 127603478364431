import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  subMainSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 120,
    paddingRight: 120,
    zIndex: 10,
  },
  sideContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  sloganEvidenceStyle: {
    color: theme.lightBlue,
  },
  pizzaAndSloganContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    marginTop: 20,
  },
  textsContainerStyle: {
    display: "flex",
    flexDirection: isMobile() ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  skillTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 38 : 70),
    color: "white",
    zIndex: 1,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
  },
  expertiseTextStyle: {
    marginLeft: 16,
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 35 : 85),
    color: theme.magenta,
    zIndex: 1,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
  },
  payloadStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(21),
    fontWeight: "bold",
    color: "white",
    letterSpacing: 2.2,
    position: "absolute",
    width: 136,
  },
  payloadContainerStyle: {
    position: "relative",
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  prendiBackground: {
    backgroundColor: theme.magenta,
    height: 25,
    width: 65,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
  },
  textTakeBackground: {
    backgroundColor: theme.magenta,
    height: 25,
    width: 45,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
  },
  textPrendiStyle: {
    zIndex: 99999,
    position: "absolute",
    whiteSpace: "nowrap",
    top: 0,
    left: 0,
  },
  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 300,
    height: 300,
    borderRadius: 150,
    position: "absolute",
    top: 200,
    left: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 150,
    height: 150,
    borderRadius: 75,
    position: "absolute",
    top: 100,
    right: 10,
  },
};

export default styles;

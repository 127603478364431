import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import styles from "./styles";
import AboutUsAnimation from "../../components/AboutUsAnimation";
import ScrollLine from "../../components/ScrollLine";
import { isMobile } from "../../utils";

class AboutUsSection extends Component {
  renderAnimations = () => {
    const { t, actualElementId } = this.props;
    const { animationsContainerStyle, animationsContainerMobileStyle } = styles;
    if (!isMobile() && actualElementId === "AboutUsSection") {
      return (
        <div style={animationsContainerStyle}>
          <AboutUsAnimation
            style={{ marginTop: 60 }}
            type="pixel"
            text={t("pixel")}
          />
          <AboutUsAnimation
            style={{ marginLeft: 100, marginRight: 100 }}
            type="pizza"
            text={t("pizza")}
          />
          <AboutUsAnimation
            style={{ marginTop: 60 }}
            type="pencil"
            text={t("pencil")}
          />
        </div>
      );
    } else if (isMobile()) {
      return (
        <Fragment>
          <div style={animationsContainerMobileStyle}>
            <AboutUsAnimation type="pixel" text={t("pixel")} />
            <AboutUsAnimation type="pizza" text={t("pizza")} />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <AboutUsAnimation type="pencil" text={t("pencil")} />
          </div>
        </Fragment>
      );
    }
  };

  renderTitle = () => {
    const { digitalTextStyle, madeInSudTextStyle } = styles;
    const { t, windowHeight } = this.props;
    if (!isMobile()) {
      return (
        <div style={{ heigth: windowHeight }}>
          <AnimatedBorderText style={digitalTextStyle}>
            {t("digital")}
          </AnimatedBorderText>
          <AnimatedBorderText style={madeInSudTextStyle}>
            {t("madeInSud")}
          </AnimatedBorderText>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <div style={digitalTextStyle}>{t("digital")}</div>
          <div style={madeInSudTextStyle}>{t("madeInSud")}</div>
        </div>
      );
    }
  };

  render() {
    const { t, windowHeight, windowWidth, top } = this.props;
    const {
      root,
      payloadTextStyle,
      textsContainerStyle,
      payloadSmallTextStyle,
      payloadSmallEvidenceTextStyle,
      mainSectionContainer,
    } = styles;
    return (
      <div
        id="AboutUsSection"
        style={Object.assign({}, root, {
          width: windowWidth,
          height: isMobile() ? "auto" : windowHeight * 2,
          top,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: isMobile() ? "auto" : windowWidth,
          })}
        >
          <div style={textsContainerStyle}>
            {isMobile() && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  alignSelf: "center",
                }}
              >
                <div
                  style={Object.assign({}, payloadTextStyle, {
                    marginBottom: 0,
                  })}
                >
                  {t("payload1")}
                </div>
                <div
                  style={Object.assign({}, payloadTextStyle, {
                    marginTop: 0,
                  })}
                >
                  {t("payload2")}
                </div>
              </div>
            )}
            {this.renderTitle()}
            {!isMobile() && (
              <div style={payloadTextStyle}>{`${t("payload1")} ${t(
                "payload2"
              )}`}</div>
            )}
            <div style={payloadSmallTextStyle}>
              {t("payloadSmall")}
              <span style={payloadSmallEvidenceTextStyle}>
                {t("payloadSmall2")}
              </span>
            </div>
          </div>
          {this.renderAnimations()}
        </div>
        <ScrollLine
          top={top + windowHeight}
          width={windowWidth}
          height={windowHeight}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ menu }) => {
  return {
    actualElementId: menu.actualElementId,
  };
};

export default compose(
  withTranslation("aboutUsSection"),
  connect(mapStateToProps)
)(AboutUsSection);

import { SET_ACTUAL_ELEMENT_ID, SET_TOP_POSITIONS, TOGGLE_MENU } from "./types";

export const toggleMenu = isOpen => {
  return { type: TOGGLE_MENU, isOpen };
};

export const setActualElementId = (id, isNext) => {
  return { type: SET_ACTUAL_ELEMENT_ID, id, isNext };
};

export const setTopPositions = topPositions => {
  return { type: SET_TOP_POSITIONS, topPositions };
};

export default {
  FRONT_SQUARES_QTY: Number(
    process.env.REACT_APP_LANDING_SQUARES_FRONT_QUANTITY
  ),
  BACK_SQUARES_QTY: Number(process.env.REACT_APP_LANDING_SQUARES_BACK_QUANTITY),
  FRONT_SQUARE_MAX: Number(
    process.env.REACT_APP_LANDING_SQUARE_FRONT_MAX_DIMENSION
  ),
  FRONT_SQUARE_MIN: Number(
    process.env.REACT_APP_LANDING_SQUARE_FRONT_MIN_DIMENSION
  ),
  BACK_SQUARE_MAX: Number(
    process.env.REACT_APP_LANDING_SQUARE_BACK_MAX_DIMENSION
  ),
  BACK_SQUARE_MIN: Number(
    process.env.REACT_APP_LANDING_SQUARE_BACK_MIN_DIMENSION
  ),
  SQUARES_MODULE_FACTOR: Number(
    process.env.REACT_APP_EVERY_HOW_MANY_SQUARES_A_SELF_ROTATION
  ),
  EXPLOSION_DURATION: Number(process.env.REACT_APP_LANDING_EXPLOSION_DURATION),
  EXPLOSION_DELAY: Number(process.env.REACT_APP_LANDING_EXPLOSION_DELAY),
  MIN_DUR_FAST_PIXELS: Number(
    process.env.REACT_APP_LANDING_MIN_DURATION_FAST_PIXELS
  ),
  MAX_DUR_FAST_PIXELS: Number(
    process.env.REACT_APP_LANDING_MAX_DURATION_FAST_PIXELS
  ),
  DIMENSIONS_CUBE_1: Number(process.env.REACT_APP_LANDING_DIMENSION_CUBE_1),
  DIMENSIONS_CUBE_2: Number(process.env.REACT_APP_LANDING_DIMENSION_CUBE_2),
  DIMENSIONS_CUBE_3: Number(process.env.REACT_APP_LANDING_DIMENSION_CUBE_3),
  DIMENSIONS_CUBE_4: Number(process.env.REACT_APP_LANDING_DIMENSION_CUBE_4),
  LANDING_LOGO_WIDTH_WEB: Number(process.env.REACT_APP_LANDING_LOGO_WIDTH_WEB),
  LANDING_LOGO_WIDTH_MOBILE: Number(
    process.env.REACT_APP_LANDING_LOGO_WIDTH_MOBILE
  ),
  K_PARALLAX_CUBES: Number(process.env.REACT_APP_LANDING_K_PARALLAX_CUBES),
  TRIGGER_DURATION: Number(
    process.env.REACT_APP_LANDING_TRIGGER_EXPLOSION_DURATION
  ),
  SCROLL_DURATION: Number(process.env.REACT_APP_SCROLL_DURATION),
  APP_NAME: process.env.REACT_APP_NAME,
  INSTAGRAM_URL: process.env.REACT_APP_URL_INSTAGRAM,
  LINKEDIN_URL: process.env.REACT_APP_URL_LINKEDIN,
  FACEBOOK_URL: process.env.REACT_APP_URL_FACEBOOK,
  MAIL_ADDRESS: process.env.REACT_APP_MAIL_ADDRESS,
};

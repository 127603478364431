import { SET_IS_ON_LINK } from "./types";

export const setIsOnLink = (width, height, el) => {
  let isOnLink = false;
  let dimensions = { width: 30, height: 30 };
  let elToAppend = null;
  if (width || height) {
    isOnLink = true;
    dimensions = { width, height };
    elToAppend = el;
  }
  return { type: SET_IS_ON_LINK, dimensions, isOnLink, elToAppend };
};

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import sliceList from "./sliceList.json";
import styles from "./styles";

class SliceList extends Component {
  renderList = () => {
    const { listElementStyle } = styles;
    const { t, selectedSlice } = this.props;
    return sliceList[selectedSlice].map((label, i) => {
      return (
        <div key={i} style={listElementStyle}>
          {t(`list.${selectedSlice}.${label}`)}
        </div>
      );
    });
  };

  render() {
    const { selectedSlice } = this.props;
    const { root, listContainerStyle } = styles;
    return (
      <div style={root}>
        {selectedSlice && (
          <div style={listContainerStyle}>{this.renderList()}</div>
        )}
      </div>
    );
  }
}

export default withTranslation("skillSection")(SliceList);

const styles = {
  a: {
    fill: "none",
    stroke: "rgba(112,112,112,0)",
  },
  c: {
    fill: "none",
    stroke: "#ff005c",
    strokeWidth: 6,
  },
  f: {
    fill: "none",
  },
  b: {
    fill: "transparent",
    stroke: "#fff",
    strokeLinecap: "square",
    strokeMiterlimit: 10,
    strokeWidth: 2,
  },
  d: { fill: "#ff005c" },
  e: { stroke: "none" },
  tooltipTextStyle: {
    color: "white",
    display: "inline-block",
    whiteSpace: "nowrap",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
  },
  amob: {
    fill: "none",
    stroke: "rgba(112,112,112,0)",
  },
  cmob: {
    fill: "none",
    stroke: "#ff005c",
    strokeWidth: 6,
  },
  fmob: {
    fill: "none",
  },
  bmob: {
    fill: "transparent",
    stroke: "#fff",
    strokeLinecap: "square",
    strokeMiterlimit: 10,
    strokeWidth: 2,
  },
  dmob: {
    fill: "#ff005c",
  },
  emob: { stroke: "none" },
};

export default styles;

import theme from "../../theme";
import { getFontSizeXD } from "../../utils";

const styles = {
  textStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: getFontSizeXD(20),
    marginBottom: 10,
    maxWidth: 600,
  },
  evidenceTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: theme.lightBlue,
    fontWeight: "bold",
    fontSize: getFontSizeXD(20),
  },
};

export default styles;

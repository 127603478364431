import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import images from "../../assets/images/images";
import ScrollLine from "../../components/ScrollLine";
import icons from "../../assets/images/icons/icons";
import AnimatedLink from "../../components/AnimatedLink";
import { isMobile } from "../../utils";
import LmcPhone from "../../components/LmcPhone";
import Sponsors from "../../components/Sponsors";

class LmcSection extends Component {
  constructor(props) {
    super(props);
    this.videoLMC = React.createRef();
  }
  /*   componentDidMount() {
    if (!isMobile()) this.videoLMC.current.play();
  } */

  render() {
    const { t, windowHeight, windowWidth, top } = this.props;
    const {
      root,
      textsContainerStyle,
      laMiaTextStyle,
      campaniaTextStyle,
      payloadTextStyle,
      payoffTextStyle,
      payoffEvidenceTextStyle,
      mainSectionContainer,
      contentContainer,
      sideContentStyle,
      rightArrowStyle,
      goToSiteTextStyle,
      textAndIconContainerStyle,
      animatedLinkGoToSiteStyle,
    } = styles;
    return (
      <div
        id="LmcSection"
        style={Object.assign({}, root, {
          top,
          width: windowWidth,
          height: isMobile() ? "auto" : windowHeight * 2,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: windowWidth,
          })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: !isMobile() ? 180 + 100 : 0,
              paddingRight: !isMobile() ? 100 : 0,
            }}
          >
            <div>
              <div
                style={{
                  fontFamily: "Jost, sans-serif",
                  color: "white",
                  letterSpacing: 0,
                  textAlign: isMobile() ? "center" : "left",
                }}
              >
                SPECIAL CASE
              </div>
              <div style={textsContainerStyle}>
                <AnimatedBorderText style={laMiaTextStyle}>
                  {t("laMia")}
                </AnimatedBorderText>
                <AnimatedBorderText style={campaniaTextStyle}>
                  {t("campania")}
                </AnimatedBorderText>
                {/* <div style={payloadTextEvidenceStyle}>{t("payloadEvidence")}</div> */}
                <div style={payloadTextStyle}>{t("payload")}</div>
                <div style={contentContainer}>
                  {isMobile() && <div style={sideContentStyle} />}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: isMobile() ? "center" : "flex-start",
                      justifyContent: "center",
                      marginBottom: isMobile() ? 30 : 0,
                      paddingTop: 16,
                    }}
                  >
                    {isMobile() && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: -116,
                          width: "60%",
                        }}
                      >
                        <img
                          alt="logo La Mia Campania"
                          src={images.logoLmc}
                          srcSet={`${images.logoLmc_2x} 2x`}
                        />
                      </div>
                    )}
                    {isMobile() && <LmcPhone />}
                    {!isMobile() && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "2rem 0",
                        }}
                      >
                        <img
                          alt="logo La Mia Campania"
                          src={images.logoLmcH}
                          srcSet={`${images.logoLmcH_2x} 2x`}
                        />
                      </div>
                    )}
                    <Sponsors small>{t("sponsor:financed")}</Sponsors>
                  </div>
                  {isMobile() && (
                    <div style={sideContentStyle}>
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://www.lamiacampania.it"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AnimatedLink
                          style={animatedLinkGoToSiteStyle}
                          icon={icons.rightArrowWhite}
                          iconWidth={28}
                          iconPosition="right"
                          iconMargin={0}
                        >
                          <div style={textAndIconContainerStyle}>
                            <div style={goToSiteTextStyle}>{t("goToSite")}</div>
                            <img
                              alt="right arrow"
                              style={rightArrowStyle}
                              src={icons.rightArrow}
                            />
                          </div>
                        </AnimatedLink>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isMobile() && (
              <div>
                <LmcPhone />
                {!isMobile() && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      aligItems: "center",
                    }}
                  >
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://www.lamiacampania.it"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AnimatedLink
                        style={animatedLinkGoToSiteStyle}
                        icon={icons.rightArrowWhite}
                        iconWidth={28}
                        iconPosition="right"
                        iconMargin={0}
                      >
                        <div style={textAndIconContainerStyle}>
                          <div style={goToSiteTextStyle}>{t("goToSite")}</div>
                          <img
                            alt="Go to Site"
                            style={rightArrowStyle}
                            src={icons.rightArrow}
                          />
                        </div>
                      </AnimatedLink>
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
          <div style={payoffTextStyle}>
            {t("payoff")}
            <span style={payoffEvidenceTextStyle}>
              {` ${t("payoffEvidence")}`}
            </span>
          </div>
        </div>
        <ScrollLine width={windowWidth} height={windowHeight} />
      </div>
    );
  }
}

export default withTranslation("lmcSection")(LmcSection);

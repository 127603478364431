import theme from "../../theme";
import { getFontSizeXD } from "../../utils";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    width: "25%",
    height: "100%",
    marginLeft: 20
  },
  listContainerStyle: {
    borderRight: `2px solid ${theme.magenta}`,
    paddingRight: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  listElementStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: theme.lightBlue,
    fontSize: getFontSizeXD(18),
    fontWeight: 900,
    textAlign: "right",
    marginBottom: 10
  }
};

export default styles;

import { getFontSizeXD } from "../../utils";

const styles = {
  root: { marginBottom: 20 },
  textStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bold",
    fontSize: getFontSizeXD(42),
    color: "white",
    display: "inline-block",
    whiteSpace: "nowrap"
  }
};

export default styles;

import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ScrollLine from "../../components/ScrollLine";
import styles from "./styles";
import InteractivePizza from "../../components/InteractivePizza";
import SliceDescription from "../../components/SliceDescription";
import { selectSlice } from "../../reducers/skill/actions";
import SliceList from "../../components/SliceList";
import SliceSlogan from "../../components/SliceSlogan";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import { isMobile } from "../../utils";

class SkillSection extends Component {
  renderPizzaWeb = () => {
    const { subMainSectionContainer, pizzaAndSloganContainer } = styles;
    const { selectSlice, selectedSlice } = this.props;
    return (
      <div style={Object.assign({}, { width: "80%" }, subMainSectionContainer)}>
        <SliceDescription selectedSlice={selectedSlice} />
        <div style={pizzaAndSloganContainer}>
          <InteractivePizza
            selectedSlice={selectedSlice}
            selectSlice={selectSlice}
          />
          <SliceSlogan selectedSlice={selectedSlice} />
        </div>
        <SliceList selectedSlice={selectedSlice} />
      </div>
    );
  };

  renderPizzaMobile = () => {
    const { selectSlice, selectedSlice } = this.props;
    return (
      <div style={{ zIndex: 10 }}>
        <InteractivePizza
          selectedSlice={selectedSlice}
          selectSlice={selectSlice}
        />
        <SliceDescription selectedSlice={selectedSlice} />
        <SliceSlogan selectedSlice={selectedSlice} />
      </div>
    );
  };

  renderPayload = () => {
    const { t, location } = this.props;
    const isEnglish = location.pathname.includes("/en");
    const {
      payloadStyle,
      payloadContainerStyle,
      prendiBackground,
      textTakeBackground,
      textPrendiStyle,
    } = styles;
    return (
      <div style={payloadContainerStyle}>
        <div style={payloadStyle}>
          {isEnglish ? (
            <div style={textTakeBackground} />
          ) : (
            <div style={prendiBackground} />
          )}
          <div style={textPrendiStyle}> {t("slice:slice")}</div>
        </div>
      </div>
    );
  };

  render() {
    const {
      mainSectionContainer,
      root,
      textsContainerStyle,
      skillTextStyle,
      expertiseTextStyle,
    } = styles;
    const { t, windowHeight, windowWidth, top } = this.props;
    return (
      <div
        id="SkillSection"
        style={Object.assign({}, root, {
          top,
          width: windowWidth,
          height: isMobile() ? "auto" + 100 : windowHeight * 2,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: windowWidth,
          })}
        >
          <div style={textsContainerStyle}>
            <AnimatedBorderText style={skillTextStyle}>
              {t("skill")}
            </AnimatedBorderText>
            <AnimatedBorderText style={expertiseTextStyle}>
              {t("expertise")}
            </AnimatedBorderText>
          </div>
          {this.renderPayload()}
          {isMobile() ? this.renderPizzaMobile() : this.renderPizzaWeb()}
        </div>
        <ScrollLine width={windowWidth} height={windowHeight} />
      </div>
    );
  }
}

const mapStateToProps = ({ skill }) => {
  return {
    selectedSlice: skill.selectedSlice,
  };
};

export default compose(
  connect(mapStateToProps, { selectSlice }),
  withTranslation("skillSection"),
  withRouter
)(SkillSection);

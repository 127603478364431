import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: isMobile() ? "inherit" : "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: isMobile() ? theme.defaultMargin : 0,
    paddingRight: isMobile() ? theme.defaultMargin : 0,
  },
  textsContainerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  digitalTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 38 : 70),
    color: "white",
    zIndex: 1,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
    width: isMobile() ? "100%" : "auto",
  },
  madeInSudTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 35 : 85),
    color: theme.magenta,
    zIndex: 1,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
    width: isMobile() ? "100%" : "auto",
    marginBottom: isMobile() ? 10 : 0,
  },
  payloadTextStyle: {
    fontFamily: "Jost, sans-serif",
    textAlign: "center",
    fontSize: getFontSizeXD(21),
    fontWeight: "bold",
    color: "white",
    letterSpacing: isMobile() ? 0 : 2.2,
    marginTop: 16,
    marginBottom: 16,
    zIndex: 1,
  },
  payloadSmallTextStyle: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(20),
    width: isMobile() ? "auto" : "60%",
    textAlign: "center",
  },
  payloadSmallEvidenceTextStyle: { color: theme.lightBlue },
  animationsContainerStyle: { display: "flex", marginTop: 20 },
  animationsContainerMobileStyle: {
    display: "flex",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "space-around",
  },
  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 300,
    height: 300,
    borderRadius: 150,
    position: "absolute",
    top: 200,
    left: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 150,
    height: 150,
    borderRadius: 75,
    position: "absolute",
    top: 100,
    right: 10,
  },
};

export default styles;

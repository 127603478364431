import { isMobile } from "../../utils";

const style = {
  root: {
    width: "60%",
    minWidth: isMobile() ? "90vw" : 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: isMobile() ? "10vh" : "15vh",
    paddingTop: "10vh",
  },
  images: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  description: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    letterSpacing: 0,
    textAlign: "center",
  },
};

export default style;

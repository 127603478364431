import { Power0, TweenLite } from "gsap";
import config from "./config";
import theme from "./theme";
import {
  circlesPositions,
  isMobile,
  slicesPositions,
  slicesPositionsMobile,
} from "./utils";

export const sectionsOrder = [
  "IntroSection",
  "WelcomeSection",
  "AboutUsSection",
  "LmcSection",
  "PixxerSection",
  "SkillSection",
  "HiringSection",
  "ContactUsSection",
];

export const animateText = (actualElementId, dur, isNext) => {
  let skewX = isNext ? -5 : 5;
  let skewY = isNext ? 5 : -5;
  TweenLite.to(`#${actualElementId} .noBorderText`, dur / 2, {
    scaleX: 0.98,
    scaleY: 0.98,
    skewType: "simple",
    skewX,
    skewY,
  });
  TweenLite.to(`#${actualElementId} .borderText`, dur / 2, {
    scaleX: 0.98,
    scaleY: 0.98,
    skewType: "simple",
    skewX,
    skewY,
    delay: 0.3,
  });
  TweenLite.to(`#${actualElementId} .noBorderText`, dur / 2, {
    scaleX: 1,
    scaleY: 1,
    skewType: "simple",
    skewX: 0,
    skewY: 0,
    delay: dur / 2,
  });
  TweenLite.to(`#${actualElementId} .borderText`, dur / 2, {
    scaleX: 1,
    scaleY: 1,
    skewType: "simple",
    skewX: 0,
    skewY: 0,
    delay: dur / 2 + 0.3,
  });
};

export const animateCircles = (dur, section) => {
  let opacity = section === "IntroSection" ? 0 : 1;
  TweenLite.to(
    "#bigCircle",
    dur,
    {
      opacity,
    },
    0
  );
  TweenLite.to(
    "#bigCircle",
    dur * 2,
    {
      left: circlesPositions[section].leftBig,
      top: circlesPositions[section].topBig,
    },
    0
  );
  TweenLite.to(
    "#smallCircle",
    dur,
    {
      opacity,
    },
    0
  );
  TweenLite.to(
    "#smallCircle",
    dur * 2,
    {
      left: circlesPositions[section].leftSmall,
      top: circlesPositions[section].topSmall,
    },
    0
  );
};

export const animateSection = (actualElementId, dur, top, callback) => {
  TweenLite.to(
    `#${actualElementId}`,
    dur,
    {
      top,
      onComplete: callback ? callback : () => {},
    },
    0
  );
  if (actualElementId === sectionsOrder[sectionsOrder.length - 1]) {
    TweenLite.to(
      "#animatedScrollIconContainer, #animatedScrollIconText",
      0.5,
      {
        opacity: 0,
      },
      0
    );
  } else {
    TweenLite.to(
      "#animatedScrollIconContainer, #animatedScrollIconText",
      0.5,
      {
        opacity: 1,
      },
      0
    );
  }
};

export const animateBreadcrumbBar = (dur, isShow) => {
  let left = isShow ? 80 : -80;
  TweenLite.to(
    "#BreadcrumbBar",
    dur,
    {
      left,
    },
    0
  );
};

export const animateLogoThumb = (dur, isShow) => {
  let opacity = isShow ? 1 : 0;
  let left = isShow ? 80 : -63;
  TweenLite.to(
    "#LogoThumb",
    dur,
    {
      opacity,
      left,
    },
    0
  );
};

export const animateMenu = (isOpen) => {
  const dur = config.SCROLL_DURATION;
  if (isOpen) {
    TweenLite.to("#MenuPage1", dur, {
      left: 0,
    });
    TweenLite.to(
      ".scrollContainer, #animatedScrollIconContainer, #animatedScrollIconText",
      dur,
      {
        left: -window.innerWidth,
      }
    );
    TweenLite.to("#MenuButton", dur, {
      right: window.innerWidth + theme.defaultMargin,
    });
    TweenLite.to("#BreadcrumbBar", dur, {
      left: -window.innerWidth + 80,
    });
    TweenLite.to("#LogoThumb", dur, {
      left: -window.innerWidth + 80,
      opacity: 0,
    });
  } else {
    TweenLite.to("#MenuPage1", dur, {
      left: window.innerWidth,
    });
    TweenLite.to(
      ".scrollContainer, #animatedScrollIconContainer, #animatedScrollIconText",
      dur,
      {
        left: 0,
      }
    );
    TweenLite.to("#MenuButton", dur, {
      right: theme.defaultMargin,
    });
    TweenLite.to("#BreadcrumbBar ", dur, {
      left: 80,
    });
    TweenLite.to("#LogoThumb", dur, {
      left: 80,
      opacity: 1,
    });
  }
};

export const animateMouse = (height, padding, display) => {
  if (height && padding) {
    TweenLite.to("#cursor1", 0.1, {
      ease: Power0.easeNone,
      backgroundColor: theme.magenta,
      border: "none",
      borderRadius: height / 2 + padding,
      padding,
      display: display ? display : "inline-block",
    });
  } else {
    TweenLite.to("#cursor1", 0.1, {
      ease: Power0.easeNone,
      borderRadius: 0,
      border: "1px solid white",
      backgroundColor: "transparent",
      padding: 0,
    });
  }
};

export const animateMenuButton = (open) => {
  TweenLite.to("#MenuButton", 0.5, { opacity: open ? 1 : 0 });
};

export const animateSlice = (prevSlice, slice) => {
  const slicePosition = isMobile()
    ? slicesPositionsMobile[slice]
    : slicesPositions[slice];
  if (prevSlice) {
    const prevSlicePosition = isMobile()
      ? slicesPositionsMobile[prevSlice]
      : slicesPositions[prevSlice];
    TweenLite.to(`#${prevSlice}`, 0.5, {
      x: prevSlicePosition.initX,
      y: prevSlicePosition.initY,
    });
  }
  TweenLite.to(`#${slice}`, 0.5, {
    x: slicePosition.x,
    y: slicePosition.y,
  });
};

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import { isMobile } from "../../utils";

class SliceSlogan extends Component {
  render() {
    const { textStyle, evidenceTextStyle } = styles;
    const { t, selectedSlice } = this.props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <div
          style={Object.assign({}, textStyle, {
            opacity: selectedSlice ? 1 : 0,
            width: isMobile() ? "70%" : "auto"
          })}
        >
          {t(`${selectedSlice}Slogan1`)}
          <span style={evidenceTextStyle}>
            {t(`${selectedSlice}SloganEvidence`)}
          </span>
          {t(`${selectedSlice}Slogan2`)}
        </div>
      </div>
    );
  }
}

export default withTranslation("skillSection")(SliceSlogan);

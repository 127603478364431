import { getFontSizeXD } from "../../utils";

const styles = {
  root: {
    display: "inline-block",
    whiteSpace: "nowrap",
    marginTop: 20,
  },
  textStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: getFontSizeXD(20),
    letterSpacing: 5,
    fontWeight: "bold",
    color: "white",
  },
  iconStyle: { marginRight: 15 },
  iconAndTextContainerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export default styles;

import React, { Component } from "react";
import AnimatedLogo from "../../components/AnimatedLogo";
import ScrollLine from "../../components/ScrollLine";
import styles from "./styles";
import config from "../../config";
import ScrollDownText from "../../components/ScrollDownText";
import Sponsors from "./../../components/Sponsors";
import { isMobile } from "../../utils";
import { withTranslation } from "react-i18next";
class IntroSection extends Component {
  state = {
    canRenderLogo: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ canRenderLogo: true });
    }, config.TRIGGER_DURATION * 1000);
  }

  render() {
    const { t } = this.props;
    const { root, mainSectionContainer } = styles;
    const { windowWidth, windowHeight, top } = this.props;
    const { canRenderLogo } = this.state;

    return (
      <div
        id="IntroSection"
        style={Object.assign({}, root, {
          width: windowWidth,
          height: isMobile() ? windowHeight + 100 : windowHeight * 2,
          top,
        })}
      >
        <div
          style={Object.assign({}, mainSectionContainer, {
            height: windowHeight,
            width: windowWidth,
          })}
        >
          {canRenderLogo && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "center",
                width: window.innerWidth,
                height: window.innerHeight,
              }}
            >
              <AnimatedLogo />
              <Sponsors animated>
                {!isMobile() && <>{t("sponsor:financed")}</>}
                {isMobile() && t("sponsor:financed")}
              </Sponsors>
            </div>
          )}
          {isMobile() && <ScrollDownText top={windowHeight - 50} />}
        </div>
        <ScrollLine withoutMargin width={windowWidth} height={windowHeight} />
      </div>
    );
  }
}

export default withTranslation()(IntroSection);

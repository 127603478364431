import React, { Component } from "react";
import styles from "./styles";
import images from "../../assets/images/images";

class LogoThumb extends Component {
  goToWelcomeSection = () => {
    const { loadSection, actualElementId } = this.props;
    if (actualElementId !== "WelcomeSection") {
      const el = document.getElementById("WelcomeSection");
      loadSection(el, false);
    }
  };

  render() {
    const { root } = styles;
    return (
      <div id="LogoThumb" onClick={this.goToWelcomeSection} style={root}>
        <img alt="logo" src={images.logoThumb} style={{ width: 63 }} />
      </div>
    );
  }
}

export default LogoThumb;

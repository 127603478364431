import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import icons from "../../assets/images/icons/icons";
import MenuListItem from "../MenuListItem";
import styles from "../AnimatedLink/styles";
import SendMailButton from "../../components/SendMailButton";
import { isMobile } from "../../utils";

class MenuList extends Component {
  changeLanguage = (lng, event) => {
    event.stopPropagation();
    const { i18n, history } = this.props;
    i18n.changeLanguage(lng);
    if (lng === "en") {
      history.push("/en");
    } else {
      history.push("/");
    }
  };

  renderLanguageSwitcher = () => {
    const { languageButtons, flag } = styles;
    return (
      <div style={languageButtons}>
        <img
          src={icons.itIcon}
          alt="Italian Flag"
          style={flag}
          onClick={(event) => this.changeLanguage("it", event)}
        />

        <img
          src={icons.ukIcon}
          alt="English Flag"
          style={flag}
          onClick={(event) => this.changeLanguage("en", event)}
        />
      </div>
    );
  };

  render() {
    const { t, style, loadSection, toggleMenu } = this.props;

    return (
      <div style={style}>
        <MenuListItem
          toggleMenu={toggleMenu}
          loadSection={loadSection}
          text={t("home")}
          name="WelcomeSection"
        />
        <MenuListItem
          toggleMenu={toggleMenu}
          loadSection={loadSection}
          text={t("lmc")}
          name="LmcSection"
        />
        <MenuListItem
          toggleMenu={toggleMenu}
          loadSection={loadSection}
          text={t("pixxer")}
          name="PixxerSection"
        />
        <MenuListItem
          toggleMenu={toggleMenu}
          loadSection={loadSection}
          text={t("skill")}
          name="SkillSection"
        />
        <MenuListItem
          toggleMenu={toggleMenu}
          loadSection={loadSection}
          text={t("WorkWithUs")}
          name="HiringSection"
        />
        <MenuListItem
          toggleMenu={toggleMenu}
          loadSection={loadSection}
          text={this.renderLanguageSwitcher()}
          name="LanguageSwitcher"
        />
        <br />
        {isMobile() && (
          <MenuListItem
            toggleMenu={toggleMenu}
            loadSection={loadSection}
            text={<SendMailButton />}
            name="SendMailButton"
          />
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation("menu")(MenuList));

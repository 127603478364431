import { isMobile } from "../../utils";

const styles = {
  smallCircleStyle: {
    position: "absolute",
    top: 400,
    left: 1250,
    width: 1200,
    height: 1200,
    borderRadius: 600,
    transform: "matrix(0.97, -0.26, 0.26, 0.97, 0, 0)",
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: isMobile() ? 0.5 : 0
  },
  bigCircleStyle: {
    position: "absolute",
    top: -1500,
    left: -300,
    width: 2200,
    height: 2200,
    borderRadius: 1100,
    transform: "matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)",
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: isMobile() ? 0.5 : 0
  }
};

export default styles;

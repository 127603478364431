import React, { Component } from "react";
import styles from "./styles";
import { isMobile } from "../../utils";

class AnimatedBorderText extends Component {
  render() {
    const { children, style } = this.props;
    const { root, borderTextStyle, borderTextContainerStyle } = styles;
    return (
      <div style={root}>
        {!isMobile() && (
          <div style={borderTextContainerStyle}>
            <div
              className="borderText"
              style={Object.assign({}, style, borderTextStyle)}
            >
              {children}
            </div>
          </div>
        )}
        <div style={style} className="noBorderText">
          {children}
        </div>
      </div>
    );
  }
}

export default AnimatedBorderText;

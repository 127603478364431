import React, { Component } from "react";
import Lottie from "react-lottie";
import logoAnimation from "../../animationsAE/logoAnimation/animazione-logo.json";
import { isMobile } from "../../utils";

class AnimatedLogo extends Component {
  render() {
    return (
      <div style={{ width: isMobile() ? window.innerWidth * 1.2 : "60%" }}>
        <Lottie
          width={isMobile() ? window.innerWidth * 0.8 : "70%"}
          options={{ animationData: logoAnimation, loop: false }}
        />
      </div>
    );
  }
}

export default AnimatedLogo;

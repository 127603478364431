const styles = {
  root: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    letterSpacing: 1.5,
    fontSize: 10
  }
};

export default styles;

import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const style = {
  textsContainerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: isMobile() ? "center" : "flex-start",
  },
  cardContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0px 20px",
  },
  cardContentStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(21),
    color: "white",
    letterSpacing: 0,
    zIndex: 1,
    width: "100%",
    textAlign: "left",
  },
  cardContentHeadings: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(21),
    fontWeight: "bold",
    color: "white",
    letterSpacing: 0,

    zIndex: 1,
    width: "100%",
    textAlign: "left",
  },
  cardTitle: {
    fontFamily: "Jost, sans-serif",
    fontSize: isMobile() ? getFontSizeXD(28) : getFontSizeXD(34),
    fontWeight: "bold",
    color: theme.lightBlue,
    letterSpacing: 0,
    zIndex: 1,
    width: "100%",
    textAlign: "left",
  },
  containerContentStyle: {
    display: "flex",
    padding: 20,
  },

  cardSubTitle: {
    fontFamily: "Jost, sans-serif",
    fontSize: isMobile() ? getFontSizeXD(20) : getFontSizeXD(26),
    fontWeight: "medium",
    color: "rgb(255, 0, 92)",
    letterSpacing: 0,
    marginTop: isMobile() ? 0 : 10,
    zIndex: 1,
    width: "100%",
    textAlign: "left",
  },
  iconaSocialStyle: {
    height: 35,
  },
};
export default style;

import React, { Component } from "react";
import { connect } from "react-redux";
import * as mouseActions from "./../../reducers/mouse/actions";
import styles from "./styles";
import { TweenMax, Linear } from "gsap";
import { isMobile } from "../../utils";

class CustomPointer extends Component {
  state = {
    xMain: 0,
    yMain: 0,
    xTrailing: 0,
    yTrailing: 0
  };
  handleMouseMove = e => {
    const { clientX, clientY } = e;
    const { dimensions } = this.props;
    const { width, height } = dimensions;
    if (
      clientX < 20 ||
      clientX > window.innerWidth - 20 ||
      clientY < 20 ||
      clientY > window.innerHeight - 20
    ) {
      TweenMax.to(
        "#cursor1",
        0.2,
        { width: 0, height: 0, opacity: 0, ease: Linear.easeNone },
        0
      );
      TweenMax.to(
        "#cursor2",
        0.1,
        { width: 0, height: 0, opacity: 0, ease: Linear.easeNone },
        0.1
      );
    } else {
      TweenMax.to(
        "#cursor1",
        0.2,
        { width, height, opacity: 1, ease: Linear.easeNone },
        0
      );
      TweenMax.to(
        "#cursor2",
        0.1,
        { width: 4, height: 4, opacity: 1, ease: Linear.easeNone },
        0.1
      );
    }

    this.setState(
      {
        xMain: clientX,
        yMain: clientY
      },
      () => {
        setTimeout(() => {
          this.setState({
            xTrailing: clientX,
            yTrailing: clientY
          });
        }, 100);
      }
    );
  };

  renderCursors = () => {
    const { xMain, yMain, xTrailing, yTrailing } = this.state;
    const { cursorSquare, cursorPoint } = styles;
    return (
      <div className="cursors">
        <div
          id="cursor1"
          className="cursor"
          style={Object.assign({}, cursorSquare, {
            left: xMain,
            top: yMain
          })}
        />
        <div
          id="cursor2"
          className="cursor"
          style={Object.assign(
            {},
            {
              left: xTrailing,
              top: yTrailing
            },
            cursorPoint
          )}
        />
      </div>
    );
  };

  render() {
    const { children } = this.props;
    return (
      <div
        id="app"
        style={{ cursor: "none" }}
        onMouseMove={e => this.handleMouseMove(e)}
      >
        {!isMobile() && this.renderCursors()}
        {children}
      </div>
    );
  }
}

const mapStateToProps = ({ mouse }) => {
  return {
    isOnLink: mouse.isOnLink,
    dimensions: mouse.dimensions
  };
};

export default connect(mapStateToProps, mouseActions)(CustomPointer);

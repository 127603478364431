import React, { Component } from "react";
import theme from "../../theme";
import { isMobile } from "../../utils";
import Lottie from "react-lottie";
import lineaMobileAnimation from "../../animationsAE/lineaMobile.json";

class ScrollLine extends Component {
  render() {
    const { width, height, withoutMargin } = this.props;
    let rootHeight = height;
    if (!isMobile()) {
      return (
        <div
          style={{
            height: rootHeight,
            width,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: rootHeight,
              width: 2,
              backgroundColor: theme.magenta,
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            marginTop: withoutMargin ? 0 : 50,
            marginBottom: withoutMargin ? 0 : 50,
          }}
        >
          <Lottie
            width={50 / 1.75}
            height={150 / 1.75}
            options={{ animationData: lineaMobileAnimation }}
          />
        </div>
      );
    }
  }
}

export default ScrollLine;

import React, { useEffect } from "react";
import { TimelineMax } from "gsap";
import { isMobile, getFontSizeXD } from "../../utils";
import images from "./../../assets/images/images";
import config from "../../config";
import styles from "./styles";

function Sponsors({ animated, small, children }) {
  useEffect(() => {
    if (animated) {
      let tl = new TimelineMax({ delay: config.TRIGGER_DURATION });
      tl.from("#sponsor1", { opacity: 0 }, 0).to("#sponsor1", 0.3, {
        opacity: 1,
      });
      tl.from("#sponsor2", { opacity: 0 }, 0).to("#sponsor2", 0.3, {
        opacity: 1,
      });
      tl.from("#sponsor3", { opacity: 0 }, 0).to("#sponsor3", 0.3, {
        opacity: 1,
      });
      tl.from("#sponsor4", { opacity: 0 }, 0).to("#sponsor4", 0.3, {
        opacity: 1,
      });
      tl.from("#sponsor-desc", { opacity: 0 }, 1.5).to("#sponsor-desc", 0.3, {
        opacity: 1,
      });
    }
  }, [animated]);

  return (
    <div
      style={{
        ...styles.root,
        alignItems: small && !isMobile() ? "flex-start" : "center",
      }}
    >
      <div
        style={{
          ...styles.images,
          justifyContent: small ? "flex-start" : "center",
        }}
      >
        <img
          id={animated ? "sponsor1" : ""}
          style={{
            margin: "0 16px",
            opacity: animated ? 0 : 1,
            width: isMobile() || small ? 40 : "auto",
          }}
          alt="unione europea"
          src={images.sponsor1}
          srcSet={`${images.sponsor1_2x} 2x`}
        />
        <img
          id={animated ? "sponsor2" : ""}
          style={{
            margin: "0 16px",
            opacity: animated ? 0 : 1,
            width: isMobile() || small ? 40 : "auto",
          }}
          alt="unione europea"
          src={images.sponsor2}
          srcSet={`${images.sponsor2_2x} 2x`}
        />
        <img
          id={animated ? "sponsor3" : ""}
          style={{
            margin: "0 16px",
            opacity: animated ? 0 : 1,
            width: isMobile() || small ? 40 : "auto",
          }}
          alt="unione europea"
          src={images.sponsor3}
          srcSet={`${images.sponsor3_2x} 2x`}
        />
        <img
          id={animated ? "sponsor4" : ""}
          style={{
            margin: "0 16px",
            opacity: animated ? 0 : 1,
            width: isMobile() || small ? 40 : "auto",
          }}
          alt="unione europea"
          src={images.sponsor4}
          srcSet={`${images.sponsor4_2x} 2x`}
        />
      </div>
      <div
        id={animated ? "sponsor-desc" : ""}
        style={{
          ...styles.description,
          opacity: animated ? 0 : 1,
          fontSize: getFontSizeXD(small ? 13 : 18),
          marginBottom: small ? 16 : 0,
          textAlign: small && !isMobile() ? "left" : "center",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Sponsors;

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import icons from "../../assets/images/icons/icons";
import style from "./style";

class TabLink extends Component {
  render() {
    const {
      t,
      title,
      icon,
      iconSelected,
      handleClick,
      id,
      stile,
      selezionato,
    } = this.props;
    const {
      hirings,
      rightArrowStyle,
      iconaHiringStyle,
      hiringsSelected,
    } = style;

    return (
      <div
        style={stile}
        onClick={() => {
          handleClick(id);
        }}
        id={id}
      >
        <img
          alt="selected"
          style={iconaHiringStyle}
          src={selezionato ? iconSelected : icon}
        />
        <div style={selezionato ? hiringsSelected : hirings}>{t(title)}</div>
        <img
          alt="right arrow"
          style={selezionato ? { display: "none" } : rightArrowStyle}
          src={icons.rightArrow}
        />
      </div>
    );
  }
}
export default withTranslation("TabLink")(TabLink);

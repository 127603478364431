import React, { Component } from "react";
import { connect } from "react-redux";
import AnimatedLink from "../AnimatedLink";
import styles from "./styles";
import { sectionsOrder } from "../../animationUtils";
import { isMobile } from "../../utils";
import config from "../../config";

class MenuListItem extends Component {
  handleClick = () => {
    const { toggleMenu, loadSection, name, actualElementId } = this.props;
    const el = document.getElementById(name);
    const indexEl = sectionsOrder.indexOf(name);
    const indexActualEl = sectionsOrder.indexOf(actualElementId);
    if (isMobile()) {
      setTimeout(() => {
        window.scrollTo(0, el.offsetTop);
      }, config.SCROLL_DURATION * 1000);
    } else {
      if (actualElementId !== name) {
        const isNext = indexActualEl < indexEl;
        loadSection(el, isNext);
      }
    }
    toggleMenu(false);
  };
  render() {
    const { root, textStyle } = styles;
    const { text } = this.props;
    return (
      <div onClick={this.handleClick} style={root}>
        <AnimatedLink style={textStyle}>{text}</AnimatedLink>
      </div>
    );
  }
}

const mapStateToProps = ({ menu, dimensions }) => {
  return {
    actualElementId: menu.actualElementId,
    windowHeight: dimensions.windowHeight,
  };
};

export default connect(mapStateToProps)(MenuListItem);

import theme from "../../theme";

const styles = {
  root: {
    top: theme.defaultMargin,
    left: theme.defaultMargin
  }
};

export default styles;

const styles = {
  root: {
    position: "absolute",
    top: 100,
    left: -63,
    opacity: 0
  }
};

export default styles;

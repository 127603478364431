import { SET_IS_ON_LINK } from "./actions/types";

const INITIAL_STATE = {
  isOnLink: false,
  dimensions: { width: 30, height: 30 },
  elToAppend: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_IS_ON_LINK:
      return {
        ...state,
        isOnLink: action.isOnLink,
        dimensions: action.dimensions,
        elToAppend: action.elToAppend
      };
    default:
      return state;
  }
};

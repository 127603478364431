import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const style = {
  linkStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: getFontSizeXD(21),
    color: theme.lightBlue,
    fontWeight: "bold",
    marginBottom: "20px",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  },
  contenitoreHiringStyle: {
    width: isMobile() ? "100%" : "80%",
    marginTop: "50px",
    paddingBottom: isMobile() ? 0 : "20px",
    display: "flex",
    justifyContent: isMobile() ? "center" : "start",
    borderBottom: isMobile() ? "none" : "1px solid rgb(255, 0, 92)",
  },

  hiringItem: {
    display: "flex",
  },
  rightArrowStyle: {
    width: 28,
  },
  iconaHiringStyle: {
    height: 35,
    marginRight: "10px",
  },

  animatedLink: {
    display: "inline-block",
    whiteSpace: "nowrap",
    marginBottom: 30,
  },
  textAndIconContainerStyle: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    paddingLeft: 10,
    marginBottom: 10,
    marginLeft: isMobile() ? "20px" : 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  textAndIconContainerStyleSelected: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    marginBottom: 10,
    marginLeft: isMobile() ? "20px" : 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    backgroundColor: "rgb(255, 0, 92)",
    borderRadius: "30px",
  },
  hirings: {
    fontSize: getFontSizeXD(21),
    fontFamily: "Montserrat, sans-serif",
    color: theme.lightBlue,
    fontWeight: "700",
    whiteSpace: "nowrap",
  },
  hiringsSelected: {
    fontSize: getFontSizeXD(21),
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontWeight: "700",
    whiteSpace: "nowrap",
  },
};

export default style;

import React, { Component } from "react";
import Lottie from "react-lottie";
import _ from "lodash";
import styles from "./style";
import pizzaAnimation from "../../animationsAE/pizza.json";
import pixelAnimation from "../../animationsAE/pixel.json";
import pencilAnimation from "../../animationsAE/pencil.json";
import pencilDotsAnimation from "../../animationsAE/pencilDots.json";
import pixelDotsAnimation from "../../animationsAE/pixelDots.json";
import pizzaDotsAnimation from "../../animationsAE/pizzaDots.json";
import { isMobile } from "../../utils";

class AboutUsAnimation extends Component {
  state = {
    animationData: null,
    canRenderDots: false,
    renderDots: null
  };
  componentDidMount() {
    const { type } = this.props;
    let animationData;
    let renderDots;
    switch (type) {
      case "pizza":
        animationData = pizzaAnimation;
        renderDots = this.renderPizzaDots;
        break;
      case "pixel":
        animationData = pixelAnimation;
        renderDots = this.renderPixelDots;
        break;
      case "pencil":
        animationData = pencilAnimation;
        renderDots = this.renderPencilDots;
        break;
      default:
        return;
    }
    this.setState({ animationData, renderDots });

    setTimeout(() => {
      this.setState({ canRenderDots: true });
    }, 3000);
  }

  renderPencilDots = () => {
    const { dotsContainerStyle } = styles;
    return (
      <div style={dotsContainerStyle}>
        <Lottie options={{ animationData: pencilDotsAnimation }} />
      </div>
    );
  };

  renderPixelDots = () => {
    const { dotsContainerStyle } = styles;
    return (
      <div style={dotsContainerStyle}>
        <Lottie options={{ animationData: pixelDotsAnimation }} />
      </div>
    );
  };

  renderPizzaDots = () => {
    const { dotsContainerStyle } = styles;
    return (
      <div style={dotsContainerStyle}>
        <Lottie options={{ animationData: pizzaDotsAnimation }} />
      </div>
    );
  };

  render() {
    const { animationData } = this.state;
    const { style, text } = this.props;
    const { root, textStyle, animationContainerStyle } = styles;
    if (_.isNull(animationData)) {
      return <div />;
    }
    return (
      <div style={root}>
        <div style={Object.assign({}, style, animationContainerStyle)}>
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <Lottie
              options={{
                animationData,
                loop: isMobile()
              }}
            />
          </div>
          {this.state.canRenderDots && this.state.renderDots()}
        </div>
        <div style={textStyle}>{text}</div>
      </div>
    );
  }
}

export default AboutUsAnimation;

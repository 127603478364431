import React, { Component } from "react";
import styles from "./styles";
import images from "../../assets/images/images";
import { isMobile } from "../../utils";
import config from "../../config";

class LogoThumbMobile extends Component {
  goToWelcomeSection = () => {
    const { loadSection, actualElementId, toggleMenu } = this.props;
    const el = document.getElementById("WelcomeSection");
    if (isMobile()) {
      if (!toggleMenu) {
        return;
      }
      setTimeout(() => {
        window.scrollTo(0, el.offsetTop);
      }, config.SCROLL_DURATION * 1000);
      toggleMenu(false);
    } else {
      if (actualElementId !== "WelcomeSection") {
        loadSection(el, false);
      }
    }
  };
  render() {
    const { root } = styles;
    return (
      <div onClick={this.goToWelcomeSection} style={root}>
        <img alt="logo" src={images.logoThumb} style={{ width: 40 }} />
      </div>
    );
  }
}

export default LogoThumbMobile;

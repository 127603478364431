import img0 from "./images/img_0.png";

export default {
  v: "5.6.5",
  fr: 29.9700012207031,
  ip: 0,
  op: 29.0000011811942,
  w: 200,
  h: 200,
  nm: "MOUSE",
  ddd: 0,
  assets: [{ id: "image_0", w: 154, h: 282, p: img0, e: 0 }],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Livello forma 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [99.833, 21.75, 0], ix: 2 },
        a: { a: 0, k: [26.25, -48.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-0.002, -0.625]
                        ],
                        o: [
                          [0.002, 0.625],
                          [0, 0]
                        ],
                        v: [
                          [26.493, -48.25],
                          [26.5, -45.75]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 6,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-0.063, -5.438]
                        ],
                        o: [
                          [0.062, 5.437],
                          [0, 0]
                        ],
                        v: [
                          [26.25, -48.25],
                          [26.5, -26.5]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-0.375, -23.25]
                        ],
                        o: [
                          [0.375, 23.25],
                          [0, 0]
                        ],
                        v: [
                          [25, -48.25],
                          [26.5, 44.75]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 14,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-0.19, -22.625]
                        ],
                        o: [
                          [0.19, 22.625],
                          [0, 0]
                        ],
                        v: [
                          [25.741, -19.5],
                          [26.5, 71]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-0.067, -8]
                        ],
                        o: [
                          [0.067, 8],
                          [0, 0]
                        ],
                        v: [
                          [26.232, 39],
                          [26.5, 71]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 22.0000008960784,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0.001, 0.062]
                        ],
                        o: [
                          [-0.001, -0.062],
                          [0, 0]
                        ],
                        v: [
                          [26.502, 71.25],
                          [26.5, 71]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: "Tracciato 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.873529411765, 0.193137254902, 0.353921568627, 1],
                ix: 3
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0]
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 1,
                    s: [100]
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 22,
                    s: [100]
                  },
                  { t: 23.0000009368092, s: [0] }
                ],
                ix: 4
              },
              w: { a: 0, k: 7, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Traccia 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Trasformazione"
            }
          ],
          nm: "Forma 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: -1.00000004073083,
      op: 479.00001951007,
      st: -1.00000004073083,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "Rectangle Copy",
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [100, 105.5, 0],
              to: [0, 3.333, 0],
              ti: [0, 0.25, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 19,
              s: [100, 125.5, 0],
              to: [0, -0.25, 0],
              ti: [0, 3.583, 0]
            },
            { t: 29.0000011811942, s: [100, 104, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [77, 141, 0], ix: 1 },
        s: { a: 0, k: [40.706, 40.706, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 480.000019550801,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

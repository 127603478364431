import { isMobile } from "../../utils";

const styles = {
  lmcAnimationMaskStyle: {
    maxWidth: isMobile() ? 250 : 370,
  },
  appContainerStyle: {
    position: "relative",
    width: !isMobile() ? 570 : 500,
    display: "flex",
    justifyContent: "center",
  },
};

export default styles;

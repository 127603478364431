import { isMobile } from "./utils";

const theme = {
  magenta: "#ff005c",
  purple: "#21005c",
  lightBlue: "#00E7FF",
  defaultMargin: isMobile() ? 35 : 80
};

export default theme;

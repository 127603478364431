import React, { Component } from "react";
import { isMobile } from "../../utils";
import TabLink from "./TabLink";
import { withTranslation } from "react-i18next";
import style from "./style";
import AnimatedLink from "../AnimatedLink";

class TabContainer extends Component {
  render() {
    const { positions, handleClick, selected, t } = this.props;
    const {
      contenitoreHiringStyle,
      textAndIconContainerStyle,
      textAndIconContainerStyleSelected,
    } = style;

    return (
      <>
        <div style={contenitoreHiringStyle} id="TabContainer">
          <div
            id="hiringScroll"
            style={{
              display: isMobile() ? "flex" : "block",
              overflow: isMobile() ? "scroll" : "unset",
            }}
            className="hiddenScrollBar"
          >
            {positions.map((position) => {
              const selezionato = position.id === selected.id;

              return (
                <AnimatedLink key={position.id}>
                  <TabLink
                    title={position.title}
                    icon={position.icon}
                    iconSelected={position.iconSelected}
                    id={position.id}
                    handleClick={() => handleClick(position)}
                    stile={
                      selezionato
                        ? textAndIconContainerStyleSelected
                        : textAndIconContainerStyle
                    }
                    selezionato={selezionato}
                  />
                </AnimatedLink>
              );
            })}
          </div>
        </div>
        <div
          style={{
            width: "80%",
            paddingTop: "20px",
            color: "white",
            display: isMobile() ? "none" : "block",
            fontFamily: "Jost, sans-serif",
          }}
        >
          {t("emailInstruction:emailInstruction")}{" "}
          <a
            href={`mailto:${"hr@pixxa.it"}`}
            style={{ textDecoration: "none", color: "rgb(255, 0, 92)" }}
          >
            hr@pixxa.it
          </a>{" "}
          {t("emailInstruction:emailInstructionEnd")}
        </div>
      </>
    );
  }
}
export default withTranslation("TabContainer")(TabContainer);

const styles = {
  root: { position: "relative" },
  borderTextContainerStyle: {
    position: "absolute",
    top: 0,
    left: 0
  },
  borderTextStyle: {
    color: "transparent",
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1
  }
};

export default styles;

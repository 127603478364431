import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: isMobile() ? "space-around" : "center",
    alignItems: "center",
    zIndex: 10,
  },
  textsContainerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  welcomeToTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 35 : 70),
    color: "white",
    zIndex: 1,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
  },
  digitalHouseTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 44 : 85),
    color: theme.magenta,
    zIndex: 1,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
  },
  payloadTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: getFontSizeXD(21),
    fontWeight: "bold",
    color: "white",
    letterSpacing: 2.2,
    marginTop: isMobile() ? 30 : 16,
    zIndex: 1,
    textAlign: "center",
    marginLeft: theme.defaultMargin,
    marginRight: theme.defaultMargin,
    marginBottom: 16,
  },
  payloadSmallTextStyle: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(20),
    width: isMobile() ? "80%" : "60%",
    textAlign: "center",
  },
  payloadEvidenceTextStyle: {
    color: theme.lightBlue,
  },
  appContainerStyle: {
    position: "absolute",
    right: 0,
    bottom: 80,
  },
  appLineStyle: {
    width: 350,
    height: 2,
    backgroundColor: theme.magenta,
  },
  imageContainerStyle: {
    position: "absolute",
    bottom: -10,
    right: 30,
    width: 252,
    height: 162,
    border: "2px solid white",
    overflow: "hidden",
    zIndex: 10,
  },
  imageStyle: {
    height: 162,
  },
  appNameContainerStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  appNameStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(21),
    letterSpacing: 2.2,
    marginTop: 16,
    marginRight: 24,
  },
  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 300,
    height: 300,
    borderRadius: 150,
    position: "absolute",
    top: 200,
    left: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 150,
    height: 150,
    borderRadius: 75,
    position: "absolute",
    top: 100,
    right: 10,
  },
  specialCaseTextStyle: {
    textAlign: "center",
    fontWeight: "bold",
    letterSpacing: 2.2,
    color: theme.lightBlue,
    fontSize: getFontSizeXD(16),
    fontFamily: "Jost, sans-serif",
  },
  watchProjectTextStyle: {
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(21),
    letterSpacing: 2.2,
    textDecoration: "underline",
  },
};

export default styles;

import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const style = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: isMobile() ? 20 : 0,
    marginBottom: isMobile() ? 20 : 0,
    zIndex: 10
  },
  textStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: getFontSizeXD(21),
    color: theme.lightBlue,
    fontWeight: "bold",
    marginTop: 10
  },
  dotsContainerStyle: { position: "absolute", top: 0, left: 0 },
  animationContainerStyle: {
    position: "relative",
    width: 130,
    height: 130
  }
};

export default style;

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AnimatedLink from "../AnimatedLink";
import styles from "./styles";
import icons from "../../assets/images/icons/icons";
import config from "../../config";

class SendMailButton extends Component {
  render() {
    const { t, style } = this.props;
    const { root, textStyle, iconStyle, iconAndTextContainerStyle } = styles;
    const { MAIL_ADDRESS } = config;
    return (
      <a
        href={`mailto:${MAIL_ADDRESS}`}
        style={{ textDecoration: "none", zIndex: 10 }}
      >
        <div style={style}>
          <AnimatedLink style={root} icon={icons.sendMailWhite}>
            <div style={iconAndTextContainerStyle}>
              <img alt="Send mail" src={icons.sendMail} style={iconStyle} />
              <div style={textStyle}>{t("sendMailButton")}</div>
            </div>
          </AnimatedLink>
        </div>
      </a>
    );
  }
}

export default withTranslation("menu")(SendMailButton);

const styles = {
  root: {
    position: "absolute",
    width: 20,
    top: 0,
    left: -80,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  textContainerStyle: {
    transform: "rotate(-90deg)",
    height: 18,
    width: 300,
    position: "absolute",
    left: -188,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  textsMask: {
    overflow: "hidden",
    width: 20,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  textStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: 15,
    fontWeight: "bold",
    letterSpacing: 3.75,
    whiteSpace: "nowrap"
  },
  iconContainerStyle: {
    position: "absolute",
    bottom: 20
  },
  iconStyle: {
    marginBottom: 10
  }
};

export default styles;

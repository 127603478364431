import {
  SET_ACTUAL_ELEMENT_ID,
  SET_TOP_POSITIONS,
  TOGGLE_MENU,
} from "./actions/types";
import { sectionsOrder } from "../../animationUtils";

const INITIAL_STATE = {
  isOpen: false,
  actualElementId: "IntroSection",
  isNext: true,
  topPositions: {
    IntroSection: 0,
    WelcomeSection:
      window.innerHeight * sectionsOrder.indexOf("WelcomeSection"),
    AboutUsSection:
      window.innerHeight * sectionsOrder.indexOf("AboutUsSection"),
    PixxerSection: window.innerHeight * sectionsOrder.indexOf("PixxerSection"),
    SkillSection: window.innerHeight * sectionsOrder.indexOf("SkillSection"),
    LmcSection: window.innerHeight * sectionsOrder.indexOf("LmcSection"),
    ContactUsSection:
      window.innerHeight * sectionsOrder.indexOf("ContactUsSection"),
    HiringSection: window.innerHeight * sectionsOrder.indexOf("HiringSection"),
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case SET_ACTUAL_ELEMENT_ID:
      return {
        ...state,
        actualElementId: action.id,
        isNext: action.isNext,
      };
    case SET_TOP_POSITIONS:
      return { ...state, topPositions: action.topPositions };
    default:
      return state;
  }
};

import { getFontSizeXD } from "../../utils";
import theme from "../../theme";

const styles = {
  root: {
    position: "absolute",
    top: theme.defaultMargin + 20,
    right: theme.defaultMargin,
    zIndex: 100
  },
  textStyle: {
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontSize: getFontSizeXD(15),
    letterSpacing: 3.75,
    fontWeight: "bold",
    display: "inline-block",
    whiteSpace: "nowrap"
  }
};

export default styles;

import React, { Component } from "react";

import styles from "./styles";

class ScrollDownText extends Component {
  render() {
    const { root } = styles;
    const { top, bottom } = this.props;
    return (
      <div style={Object.assign({}, root, { top, bottom })}>scroll down</div>
    );
  }
}

export default ScrollDownText;

import React, { Component } from "react";
import AnimatedBackground from "../../components/AnimatedBackground";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { TweenLite } from "gsap";
import { positions, preventFromScrolling } from "../../utils";
import config from "../../config";
import AnimatedScrollIcon from "../../components/AnimatedScrollIcon";
import WelcomeSection from "../../sections/WelcomeSection";
import IntroSection from "../../sections/IntroSection";
import BreadcrumbBar from "../../components/BreadcrumbBar";
import AboutUsSection from "../../sections/AboutUsSection";
import * as menuActions from "./../../reducers/menu/actions";
import {
  animateText,
  animateCircles,
  animateSection,
  animateBreadcrumbBar,
  sectionsOrder,
  animateLogoThumb,
} from "../../animationUtils";
import MenuButton from "../../components/MenuButton";
import MenuPage from "../MenuPage";
import PixxerSection from "../../sections/PixxerSection";
import SkillSection from "../../sections/SkillSection";
import LmcSection from "../../sections/LmcSection";
import LogoThumb from "../../components/LogoThumb";
import ContactUsSection from "../../sections/ContactUsSection";
import HiringSection from "../../sections/HiringSection";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.isScrolling = false;
  }

  componentDidMount() {
    this.addMousewheelListener();
    preventFromScrolling();
    this.showComingSoon();

    //Sostituire l'url

    if (window.location.hash) {
      const hashUrl = window.location.hash;
      if (hashUrl.includes("workWithUs") && hashUrl.length > 10) {
        const posizione = document.getElementById(hashUrl.slice(11));
        if (posizione || (!posizione && hashUrl === "#workWithUs")) {
          const { isNext } = this.props;
          const dur = config.SCROLL_DURATION;
          const el = document.getElementById("HiringSection");
          animateBreadcrumbBar(dur, isNext);
          animateLogoThumb(dur, isNext);
          this.loadSection(el, false);
        } else {
          let noHashURL = window.location.href.replace(/#.*$/, "");
          window.history.replaceState("", document.title, noHashURL);
        }
      } else {
        const el = document.getElementById(hashUrl.slice(1) + "Section");
        const introSection = document.getElementById("IntroSection");
        if (el && el !== introSection) {
          const { isNext } = this.props;
          const dur = config.SCROLL_DURATION;
          animateBreadcrumbBar(dur, isNext);
          animateLogoThumb(dur, isNext);
          this.loadSection(el, false);
        } else {
          let noHashURL = window.location.href.replace(/#.*$/, "");
          window.history.replaceState("", document.title, noHashURL);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actualElementId, isNext } = this.props;

    const dur = config.SCROLL_DURATION;
    if (prevProps.actualElementId !== this.props.actualElementId) {
      let actualElToCompare = !isNext ? "IntroSection" : "WelcomeSection";

      if (actualElementId === actualElToCompare) {
        animateBreadcrumbBar(dur, isNext);
        animateLogoThumb(dur, isNext);
      }
      this.changeBreadcrumbBarValue(isNext);
      animateSection(actualElementId, dur, 0, this.onSectionLoaded);
      animateText(actualElementId, config.SCROLL_DURATION, isNext);
      animateCircles(dur, actualElementId);
    }
    if (
      !this.props.isOpen &&
      prevProps.isOpen &&
      actualElementId === "IntroSection"
    ) {
      setTimeout(() => {
        animateBreadcrumbBar(dur, false);
        animateLogoThumb(dur, false);
      });
    }
  }

  addMousewheelListener = () => {
    window.addEventListener("wheel", this.handleNavigation);
    window.addEventListener("gesturestart", function(e) {
      console.log(e);
    });
    window.addEventListener("gesturechange", function(e) {
      console.log(e);
    });
    window.addEventListener("gestureend", function(e) {
      console.log(e);
    });
  };

  handleNavigation = (e) => {
    const { isOpen, actualElementId } = this.props;
    window.removeEventListener("mousewheel", this.handleNavigation);
    let prevEl = document.getElementById(actualElementId).previousSibling;
    let nextEl = document.getElementById(actualElementId).nextSibling;
    if (!this.isScrolling && !isOpen) {
      this.isScrolling = true;
      if (e.deltaY < 0 && prevEl) {
        // if scrolling to TOP
        this.scrollSection(prevEl);
      } else if (e.deltaY > 0 && nextEl) {
        this.scrollSection(nextEl, true);
      } else {
        this.isScrolling = false;
      }
    }
  };

  scrollSection = (el, isDown) => {
    this.loadSection(el, isDown);
  };

  changeBreadcrumbBarValue = (goNext) => {
    const dur = config.SCROLL_DURATION;
    const { actualElementId } = this.props;
    const actualElIndex = sectionsOrder.indexOf(actualElementId);
    let left = goNext
      ? -200 + 20 * (actualElIndex - 1)
      : -200 + 20 * (actualElIndex - 1);
    TweenLite.to("#BCTexts", dur, { left }, 0);
  };

  loadSection = (nextEl, isNext) => {
    const { actualElementId, setActualElementId, windowHeight } = this.props;
    let dur = config.SCROLL_DURATION;
    let height = isNext ? -windowHeight * 2 : windowHeight * 2;
    animateSection(actualElementId, dur, height);
    animateText(actualElementId, dur, isNext);
    setActualElementId(nextEl.id, isNext);
  };

  onSectionLoaded = () => {
    const { actualElementId, windowHeight, setTopPositions } = this.props;

    const actualElementIndex = sectionsOrder.indexOf(actualElementId);
    let topArray = {};
    sectionsOrder.forEach((id, i) => {
      if (i < actualElementIndex) {
        topArray[id] = -windowHeight * 2 * (actualElementIndex - i);
      } else if (i > actualElementIndex) {
        topArray[id] = windowHeight * 2 * (i - actualElementIndex);
      }
    });
    topArray[actualElementId] = 0;
    setTopPositions(topArray);
    setTimeout(() => {
      this.isScrolling = false;
      this.addMousewheelListener();
    }, 900);
  };

  showComingSoon = () => {
    const { EXPLOSION_DELAY, TRIGGER_DURATION } = config;
    TweenLite.to("#animatedScrollIconContainer, #animatedScrollIconText", 1, {
      opacity: 1,
      delay: EXPLOSION_DELAY + TRIGGER_DURATION + 3,
    });
  };

  toggleMenu = (isOpen) => {
    this.props.toggleMenu(isOpen);
  };

  render() {
    const {
      windowHeight,
      windowWidth,
      isOpen,
      topPositions,
      actualElementId,
    } = this.props;
    let index = 0;
    const hashUrl = window.location.hash;

    if (hashUrl.includes("workWithUs") && hashUrl.length > 11) {
      const hashPosition = hashUrl.slice(11);
      index = positions.findIndex((position) => {
        return position.id === hashPosition;
      });
      if (index === -1) {
        index = 0;
        var noHashURL = window.location.href.replace(/#.*$/, "");
        window.history.replaceState("", document.title, noHashURL);
      }
    }

    return (
      <div>
        <AnimatedBackground
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        />
        {/*<div id="intro_link" style={textContainer}>
          <div
            ref={this.comingSoonText}
            // onMouseEnter={this.handleMouseEnter}
            // onMouseLeave={this.handleMouseLeave}
            style={text}
          >
            {t("comingSoon").toUpperCase()}
          </div>
        </div>*/}
        <MenuPage
          isOpen={isOpen}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          toggleMenu={this.toggleMenu}
          loadSection={this.scrollSection}
        />
        <div
          className="scrollContainer"
          style={{
            overflow: "hidden",
            width: windowWidth,
            height: windowHeight,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <IntroSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["IntroSection"]}
          />
          <WelcomeSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["WelcomeSection"]}
          />
          <AboutUsSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["AboutUsSection"]}
          />
          <LmcSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["LmcSection"]}
          />
          <PixxerSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["PixxerSection"]}
          />
          <SkillSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["SkillSection"]}
          />
          <HiringSection
            index={index}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["HiringSection"]}
          />
          <ContactUsSection
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            top={topPositions["ContactUsSection"]}
          />
        </div>
        <LogoThumb
          loadSection={this.scrollSection}
          actualElementId={actualElementId}
        />
        <MenuButton onClick={this.toggleMenu} />
        <AnimatedScrollIcon />
        <BreadcrumbBar windowWidth={windowWidth} windowHeight={windowHeight} />
      </div>
    );
  }
}

const mapStateToProps = ({ dimensions, menu }) => {
  return {
    windowWidth: dimensions.windowWidth,
    windowHeight: dimensions.windowHeight,
    isOpen: menu.isOpen,
    actualElementId: menu.actualElementId,
    isNext: menu.isNext,
    topPositions: menu.topPositions,
  };
};

export default compose(
  connect(mapStateToProps, menuActions),
  withTranslation("landingPage")
)(LandingPage);

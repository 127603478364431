import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";

import store from "./store";
import "./App.css";
import routes from "./routes";
import history from "./history";
import CustomPointer from "./components/CustomPointer";
import ResponsiveAppContainer from "./components/ResponsiveAppContainer";
import { isMobile } from "./utils";

export default function App(props) {
  const { t } = useTranslation("landingPage");
  return (
    <Provider store={store}>
      <ResponsiveAppContainer>
        <CustomPointer>
          <CookieConsent
            location="bottom"
            buttonText={t("acceptCookie")}
            cookieName="myAwesomeCookieName2"
            style={{
              background: "rgba(0,0,0,0.5)",
              fontSize: "13px",
              justifyContent: "center",
            }}
            contentStyle={{ textAlign: isMobile() ? "center" : "left" }}
            buttonStyle={{
              color: "white",
              backgroundColor: "rgb(255,0,92)",
              borderRadius: 10,
              fontSize: "13px",
            }}
            expires={150}
          >
            {`${t("cookieBanner")} `}
            <span>
              <a
                style={{ textDecoration: "underline", color: "white" }}
                href="https://www.iubenda.com/privacy-policy/13194507/legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("termsAndConditions")}
              </a>
            </span>
            .
          </CookieConsent>
          <Router history={history}>{routes(t)}</Router>
        </CustomPointer>
      </ResponsiveAppContainer>
    </Provider>
  );
}

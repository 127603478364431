import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./style";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import TabContainer from "../../components/TabsContainer/TabContainer";
import { withRouter } from "react-router-dom";
import { isMobile } from "../../utils";
import { positions } from "../../utils";
import HiringCard from "../../components/HiringCard/HiringCard";
import ScrollLine from "../../components/ScrollLine";

class HiringSection extends Component {
  //commentato per far girare mobile
  state = { selected: positions[this.props.index ? this.props.index : 0] };

  handleClick(position) {
    this.setState({ selected: position });
  }
  renderPayload = () => {
    const {
      payloadStyle,
      payloadContainerStyle,
      prendiBackground,
      textTakeBackground,
      textPosizioniStyle,
    } = styles;
    const { t, location } = this.props;
    const isEnglish = location.pathname.includes("/en");
    return (
      <div style={payloadContainerStyle}>
        <div style={payloadStyle}>
          <div style={isEnglish ? textTakeBackground : prendiBackground} />
          <div style={textPosizioniStyle}>
            {isMobile()
              ? t("positionsTitle:openPositions")
              : t("positionsTitle:openPositionsRightNow")}{" "}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { t, windowWidth, windowHeight, top } = this.props;

    const {
      root,
      mainSectionContainer,
      textsContainerStyle,
      HiringTextStyle,
      weAreTextStyle,
      payloadSmallTextStyle,
    } = styles;

    return (
      <div
        id="HiringSection"
        className="Hiring"
        style={Object.assign({}, root, {
          top,
          width: windowWidth,
          height: isMobile() ? "auto" : windowHeight * 2,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: windowWidth,
            flexWrap: "wrap",
          })}
        >
          <div
            style={{
              width: "100%",
              height: isMobile() ? "80%" : "none",
              display: isMobile() ? "block" : "flex",
              padding: isMobile() ? "0" : "0 200px",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                width: isMobile() ? "100%" : "40%",
                marginBottom: isMobile() ? "20px" : 0,
              }}
            >
              <div style={textsContainerStyle}>
                <AnimatedBorderText style={weAreTextStyle}>
                  {t("work")}
                </AnimatedBorderText>
                <AnimatedBorderText style={HiringTextStyle}>
                  {t("whitUs")}
                </AnimatedBorderText>
                <div style={payloadSmallTextStyle}>{t("payloadSmall1")}</div>
                {this.renderPayload()}
              </div>
              <TabContainer
                handleClick={this.handleClick.bind(this)}
                positions={positions}
                selected={this.state.selected}
              />
            </div>
            <div
              style={{
                width: isMobile() ? "80%" : "40%",
                height: isMobile() ? "60%" : "none",
                margin: isMobile() ? "0% 10%" : "0",
                border: "1px solid rgb(255, 0, 92)",
                borderRadius: "20px",
                overflow: isMobile() ? "scroll" : "unset",
              }}
              className="hiddenScrollBar"
            >
              <HiringCard position={this.state.selected} />
            </div>
          </div>
          <div
            style={
              isMobile()
                ? {
                    color: "white",
                    width: "60%",
                    textAlign: "center",
                    fontFamily: "Jost, sans-serif",
                  }
                : { display: "none" }
            }
          >
            Invia una mail a{" "}
            <a
              href={`mailto:${"hr@pixxa.it"}`}
              style={{ textDecoration: "none", color: "rgb(255, 0, 92)" }}
            >
              hr@pixxa.it
            </a>{" "}
            allegando il tuo CV
          </div>
          <div style={{ width: "100%" }}>
            {/* <div style={isMobile() ? footerStyleMobile : footerStyle}>
              {t("footer")}
            </div>
            <div style={isMobile() ? companyStyleMobile : companyStyle}>{`${t(
              "company"
            )} - ${t("address1")} ${t("address2")} - ${t("vat")}`}</div> */}
          </div>
          {isMobile() && <div style={{ height: "20%" }} />}
        </div>
        <ScrollLine width={windowWidth} height={windowHeight} />
      </div>
    );
  }
}

export default withRouter(withTranslation("hiringSection")(HiringSection));

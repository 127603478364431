import theme from "../../theme";

const styles = {
  root: {
    position: "absolute",
    top: 0,
    left: 0
  },
  logoStickyContainerStyle: {
    paddingLeft: theme.defaultMargin,
    position: "sticky",
    top: 50,
    zIndex: 10
  }
};

export default styles;

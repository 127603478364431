const images = {
  faceBottom: require("./cubo-lat1.png"),
  faceTop: require("./cubo-lat1.png"),
  faceFront: require("./cubo-lat1.png"),
  faceBack: require("./cubo-lat3.png"),
  faceRight: require("./cubo-lat2.png"),
  faceLeft: require("./cubo-lat4.png"),
  face1: require("./CUB-06.png"),
  face2: require("./CUB-07.png"),
  face3: require("./CUB-08.png"),
  campania: require("./campania.jpg"),
  logoSmall: require("./Logo_small@2x.png"),
  logoSquare: require("./logoSquare.png"),
  iphone: require("./iphone.png"),
  skill_graphicAndVideo: require("./skill_graphicAndVideo.png"),
  skill_officeEnvironment: require("./skill_officeEnvironment.png"),
  skill_socialMediaMarketing: require("./skill_socialMediaMarketing.png"),
  skill_userExperience: require("./skill_userExperience.png"),
  skill_webAppDev: require("./skill_webAppDev.png"),
  logoThumb: require("./logoThumb.png"),
  contactUs: require("./contactUs.png"),
  logoICT: require("./logoict.png"),
  sponsor1: require("./sponsor1.png"),
  sponsor1_2x: require("./sponsor1@2x.png"),
  sponsor2: require("./sponsor2.png"),
  sponsor2_2x: require("./sponsor2@2x.png"),
  sponsor3: require("./sponsor3.png"),
  sponsor3_2x: require("./sponsor3@2x.png"),
  sponsor4: require("./sponsor4.png"),
  sponsor4_2x: require("./sponsor4@2x.png"),
  logoLmc: require("./logo_lmc.png"),
  logoLmc_2x: require("./logo_lmc@2x.png"),
  logoLmcH: require("./logo_lmc_h.png"),
  logoLmcH_2x: require("./logo_lmc_h@2x.png"),
};

export default images;

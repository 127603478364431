import img0 from "./images/img_0.png";
import img1 from "./images/img_1.png";

export default {
  v: "5.6.5",
  fr: 25,
  ip: 0,
  op: 375,
  w: 400,
  h: 400,
  nm: "Composizione 1",
  ddd: 0,
  assets: [
    { id: "image_0", w: 463, h: 422, p: img0, e: 0 },
    { id: "image_1", w: 617, h: 762, p: img1, e: 0 },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: "pallino 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [122.28, 18.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [13.667, 16.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Pallino 1",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 2.503,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8.342,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 321.154,
                  s: [100]
                },
                { t: 329.496162829496, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [-75.5, 77.625, 0],
                  to: [-1.625, 4.229, 0],
                  ti: [-0.972, -3.208, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43.377,
                  s: [-85.25, 103, 0],
                  to: [0.972, 3.208, 0],
                  ti: [4.333, 0.833, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 72.573,
                  s: [-69.667, 96.875, 0],
                  to: [-2.203, -0.424, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 95.929,
                  s: [-77.667, 92.875, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 120.954,
                  s: [-69, 87.75, 0],
                  to: [0, 0, 0],
                  ti: [2.361, 0.146, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 140.14,
                  s: [-75.5, 77.625, 0],
                  to: [-2.361, -0.146, 0],
                  ti: [1.625, -4.229, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 159.326,
                  s: [-83.167, 86.875, 0],
                  to: [-1.625, 4.229, 0],
                  ti: [-2.25, -1.667, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 183.517,
                  s: [-85.25, 103, 0],
                  to: [2.25, 1.667, 0],
                  ti: [4.333, 0.833, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 202.703,
                  s: [-69.667, 96.875, 0],
                  to: [-2.203, -0.424, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 219.386,
                  s: [-77.667, 92.875, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 241.074,
                  s: [-69, 87.75, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 260.26026026026, s: [-80.917, 78.375, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 9.176,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 15.015,
                  s: [100, 100, 100]
                },
                { t: 17.5175175175175, s: [607.694, 607.694, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Pallino 6",
          parent: 4,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5.839,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12.513,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 325.325,
                  s: [100]
                },
                { t: 331.998665331999, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [100, 101.136, 0],
                  to: [17.473, -17.424, 0],
                  ti: [-13.172, -1.136, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40.04,
                  s: [204.839, -3.409, 0],
                  to: [13.172, 1.136, 0],
                  ti: [15.591, -35.985, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65.065,
                  s: [179.032, 107.955, 0],
                  to: [-15.591, 35.985, 0],
                  ti: [41.936, 7.576, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 91.758,
                  s: [111.29, 212.5, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 119.286,
                  s: [89.17, 119.318, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.813,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [-11.559, 0.758, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 177.678,
                  s: [100, 101.136, 0],
                  to: [11.559, -0.758, 0],
                  ti: [-13.172, -1.136, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 206.039,
                  s: [204.839, -3.409, 0],
                  to: [13.172, 1.136, 0],
                  ti: [15.591, -35.985, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240.24,
                  s: [179.032, 107.955, 0],
                  to: [-15.591, 35.985, 0],
                  ti: [41.936, 7.576, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 269.436,
                  s: [111.29, 212.5, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 290.29,
                  s: [67.742, 119.318, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 314.481,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 335.335335335335, s: [164.516, 194.318, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 7.508,
                  s: [0, 0, 100]
                },
                { t: 12.5125125125125, s: [993.742, 933.515, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [201.172, 201.172], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 3,
          nm: "pallino 6",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [141.947, 119.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [4.667, 5.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Pallino 2",
          parent: 6,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5.839,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12.513,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 325.325,
                  s: [100]
                },
                { t: 331.998665331999, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [100, 101.136, 0],
                  to: [17.473, -17.424, 0],
                  ti: [-13.172, -1.136, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40.04,
                  s: [204.839, -3.409, 0],
                  to: [13.172, 1.136, 0],
                  ti: [15.591, -35.985, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65.065,
                  s: [179.032, 107.955, 0],
                  to: [-15.591, 35.985, 0],
                  ti: [41.936, 7.576, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 91.758,
                  s: [111.29, 212.5, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 119.286,
                  s: [67.742, 119.318, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.813,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [-11.559, 0.758, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 177.678,
                  s: [100, 101.136, 0],
                  to: [11.559, -0.758, 0],
                  ti: [-13.172, -1.136, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 206.039,
                  s: [204.839, -3.409, 0],
                  to: [13.172, 1.136, 0],
                  ti: [15.591, -35.985, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240.24,
                  s: [179.032, 107.955, 0],
                  to: [-15.591, 35.985, 0],
                  ti: [41.936, 7.576, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 269.436,
                  s: [111.29, 212.5, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 290.29,
                  s: [67.742, 119.318, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 314.481,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 335.335335335335, s: [164.516, 194.318, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 7.508,
                  s: [0, 0, 100]
                },
                { t: 12.5125125125125, s: [993.742, 933.515, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [201.172, 201.172], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 3,
          nm: "pallino 2",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [64.28, 49.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [4.667, 5.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Pallino 3",
          parent: 8,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5.839,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12.513,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 337.004,
                  s: [100]
                },
                { t: 345.345345345345, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [100, 101.136, 0],
                  to: [-13.297, -20.565, 0],
                  ti: [-0.608, -0.963, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40.874,
                  s: [20.217, -22.255, 0],
                  to: [7.017, 11.121, 0],
                  ti: [14.349, -33.116, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65.065,
                  s: [179.032, 107.955, 0],
                  to: [-0.579, 1.337, 0],
                  ti: [40.377, 7.294, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 91.758,
                  s: [240.322, 128.409, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 119.286,
                  s: [96.774, 182.955, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.813,
                  s: [190.322, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [-2.419, 0.758, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 177.678,
                  s: [100, 101.136, 0],
                  to: [0.239, -0.075, 0],
                  ti: [-11.872, -1.024, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 206.039,
                  s: [130.645, 142.045, 0],
                  to: [13.172, 1.136, 0],
                  ti: [3.226, -11.742, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240.24,
                  s: [179.032, 107.955, 0],
                  to: [-0.247, 0.899, 0],
                  ti: [38.725, 6.996, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 269.436,
                  s: [220.968, 192.045, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 291.959,
                  s: [243.548, 73.864, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 316.984,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 335.335335335335, s: [50, 94.318, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 7.508,
                  s: [0, 0, 100]
                },
                { t: 15.8491825158492, s: [570.998, 570.998, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [201.172, 201.172], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 3,
          nm: "pallino 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [101.613, 135, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [4.667, 5.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Pallino 7",
          parent: 10,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5.839,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12.513,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 322.823,
                  s: [100]
                },
                { t: 328.661995328662, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [100, 101.136, 0],
                  to: [-13.297, -20.565, 0],
                  ti: [-0.608, -0.963, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40.874,
                  s: [20.217, -22.255, 0],
                  to: [7.017, 11.121, 0],
                  ti: [14.349, -33.116, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65.065,
                  s: [179.032, 107.955, 0],
                  to: [-0.579, 1.337, 0],
                  ti: [40.377, 7.294, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 91.758,
                  s: [240.322, 128.409, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 119.286,
                  s: [96.774, 182.955, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.813,
                  s: [190.322, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [-2.419, 0.758, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 177.678,
                  s: [100, 101.136, 0],
                  to: [0.239, -0.075, 0],
                  ti: [-11.872, -1.024, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 206.039,
                  s: [130.645, 142.045, 0],
                  to: [13.172, 1.136, 0],
                  ti: [3.226, -11.742, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240.24,
                  s: [179.032, 107.955, 0],
                  to: [-0.247, 0.899, 0],
                  ti: [38.725, 6.996, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 269.436,
                  s: [220.968, 192.045, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 291.959,
                  s: [243.548, 73.864, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 316.984,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 335.335335335335, s: [50, 94.318, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 7.508,
                  s: [0, 0, 100]
                },
                { t: 15.8491825158492, s: [570.998, 570.998, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [201.172, 201.172], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 3,
          nm: "pallino 7",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [118.947, 50, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [4.667, 5.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Pallino 4",
          parent: 12,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5.839,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 12.513,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 322.823,
                  s: [100]
                },
                { t: 328.661995328662, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [100, 101.136, 0],
                  to: [-13.297, -20.565, 0],
                  ti: [-0.608, -0.963, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 40.874,
                  s: [20.217, -22.255, 0],
                  to: [7.017, 11.121, 0],
                  ti: [14.349, -33.116, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 65.065,
                  s: [179.032, 107.955, 0],
                  to: [-0.579, 1.337, 0],
                  ti: [40.377, 7.294, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 91.758,
                  s: [240.322, 128.409, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 119.286,
                  s: [96.774, 182.955, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.813,
                  s: [190.322, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [-2.419, 0.758, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 177.678,
                  s: [100, 101.136, 0],
                  to: [0.239, -0.075, 0],
                  ti: [-11.872, -1.024, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 206.039,
                  s: [130.645, 142.045, 0],
                  to: [13.172, 1.136, 0],
                  ti: [3.226, -11.742, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240.24,
                  s: [179.032, 107.955, 0],
                  to: [-0.247, 0.899, 0],
                  ti: [38.725, 6.996, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 269.436,
                  s: [220.968, 192.045, 0],
                  to: [-21.324, -3.852, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 291.959,
                  s: [243.548, 73.864, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 316.984,
                  s: [135.484, 1.136, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 335.335335335335, s: [50, 94.318, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 7.508,
                  s: [0, 0, 100]
                },
                { t: 15.8491825158492, s: [570.998, 570.998, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [201.172, 201.172], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 12,
          ty: 3,
          nm: "pallino 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [132.947, 99.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [4.667, 5.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "Pallino 5",
          parent: 14,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 2.503,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8.342,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 329.496,
                  s: [100]
                },
                { t: 335.335335335335, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20.02,
                  s: [-75.5, 77.625, 0],
                  to: [-1.625, 4.229, 0],
                  ti: [-0.972, -3.208, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43.377,
                  s: [-85.25, 103, 0],
                  to: [0.972, 3.208, 0],
                  ti: [4.333, 0.833, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 72.573,
                  s: [-69.667, 96.875, 0],
                  to: [-2.203, -0.424, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 95.929,
                  s: [-77.667, 92.875, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 120.954,
                  s: [-69, 87.75, 0],
                  to: [0, 0, 0],
                  ti: [2.361, 0.146, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 140.14,
                  s: [-75.5, 77.625, 0],
                  to: [-2.361, -0.146, 0],
                  ti: [1.625, -4.229, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 159.326,
                  s: [-83.167, 86.875, 0],
                  to: [-1.625, 4.229, 0],
                  ti: [-2.25, -1.667, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 183.517,
                  s: [-85.25, 103, 0],
                  to: [2.25, 1.667, 0],
                  ti: [4.333, 0.833, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 202.703,
                  s: [-69.667, 96.875, 0],
                  to: [-2.203, -0.424, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 219.386,
                  s: [-77.667, 92.875, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 241.074,
                  s: [-69, 87.75, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 260.26026026026, s: [-80.917, 78.375, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [-116.25, 97.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 9.176,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 15.015,
                  s: [100, 100, 100]
                },
                { t: 17.5175175175175, s: [607.694, 607.694, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          ef: [
            {
              ty: 34,
              nm: "Marionetta",
              np: 6,
              mn: "ADBE FreePin3",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 7,
                  nm: "Modulo Marionetta",
                  mn: "ADBE FreePin3 Puppet Engine",
                  ix: 1,
                  v: { a: 0, k: 2, ix: 1 }
                },
                {
                  ty: 0,
                  nm: "Miglioramento rotazione trama",
                  mn: "ADBE FreePin3 Auto Rotate Pins",
                  ix: 2,
                  v: { a: 0, k: 20, ix: 2 }
                },
                {
                  ty: 7,
                  nm: "Su trasparente",
                  mn: "ADBE FreePin3 On Transparent",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 }
                },
                {
                  ty: 25,
                  nm: "arap",
                  np: 3,
                  mn: "ADBE FreePin3 ARAP Group",
                  ix: 4,
                  en: 1,
                  ef: [
                    {
                      ty: 6,
                      nm: "Forme tracciate automaticamente",
                      mn: "ADBE FreePin3 Outlines",
                      ix: 1,
                      v: 0
                    },
                    {
                      ty: 1,
                      nm: "Trama",
                      np: 2,
                      mn: "ADBE FreePin3 Mesh Group",
                      ix: 2,
                      en: 1,
                      ef: [
                        {
                          nm: "Trama 1",
                          np: 8,
                          mn: "ADBE FreePin3 Mesh Atom",
                          ix: 1,
                          en: 1,
                          ef: [
                            {
                              ty: 6,
                              nm: "Trama",
                              mn: "ADBE FreePin3 Mesh",
                              ix: 1,
                              v: 0
                            },
                            {
                              ty: 0,
                              nm: "Triangoli",
                              mn: "ADBE FreePin3 Mesh Tri Count",
                              ix: 2,
                              v: { a: 0, k: 50, ix: 2 }
                            },
                            {
                              ty: 0,
                              nm: "Densità",
                              mn: "ADBE FreePin3 Mesh Tri Density",
                              ix: 3,
                              v: { a: 0, k: 10, ix: 3 }
                            },
                            {
                              ty: 0,
                              nm: "Espansione",
                              mn: "ADBE FreePin3 Mesh Expansion",
                              ix: 4,
                              v: { a: 0, k: 3, ix: 4 }
                            },
                            {
                              nm: "Deforma",
                              np: 1,
                              mn: "ADBE FreePin3 PosPins",
                              ix: 5,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Sovrapposizione",
                              np: 1,
                              mn: "ADBE FreePin3 HghtPins",
                              ix: 6,
                              en: 1,
                              ef: []
                            },
                            {
                              nm: "Rigidità",
                              np: 1,
                              mn: "ADBE FreePin3 StarchPins",
                              ix: 7,
                              en: 1,
                              ef: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [4.875, -1.656],
                        [-1.656, -4.875],
                        [-4.875, 1.656],
                        [1.656, 4.875]
                      ],
                      c: true
                    },
                    ix: 2
                  },
                  nm: "Tracciato 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.807843137255, 0.219607843137, 0.37156865737, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Riempimento 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-116.359, 96.641], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 153.099, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Trasformazione"
                }
              ],
              nm: "Rettangolo 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 14,
          ty: 3,
          nm: "pallino 5",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [75.447, 101.074, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [13.667, 16.333, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 750.750750750751,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "pixxa_dots",
      parent: 4,
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [65.59, 65.843, 0], ix: 2 },
        a: { a: 0, k: [100, 100, 0], ix: 1 },
        s: { a: 0, k: [151.284, 151.284, 100], ix: 6 }
      },
      ao: 0,
      w: 200,
      h: 200,
      ip: -1,
      op: 399.4004004004,
      st: -1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "Disegno 23",
      parent: 6,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [60.677, 51.302, 0], ix: 2 },
        a: { a: 0, k: [231.5, 211, 0], ix: 1 },
        s: { a: 0, k: [34.005, 34.005, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "Group 126",
      parent: 5,
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-13.171, -51.129, 0], ix: 2 },
        a: { a: 0, k: [308.5, 381, 0], ix: 1 },
        s: { a: 0, k: [79.223, 79.223, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 3,
      nm: "NULL 14",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [71, 246, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [74, 74, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 3,
      nm: "NULL 13",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [196, 211, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [74, 74, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 3,
      nm: "NULL 13",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [43.5, 212.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [142, 142, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 375,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

import reducers from "./reducers";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

const composeEnhancers = composeWithDevTools({
  name: `BabylonRedux`,
  realtime: true,
  trace: true,
  traceLimit: 25
});

const store = createStore(reducers, composeEnhancers());

export default store;

import { getFontSizeXD } from "../../utils";

const styles = {
  animationContainerStyle: {
    position: "absolute",
    bottom: 60,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    opacity: 0,
  },
  animatedScrollIcon: {
    width: 60,
  },
  scrollDownTextStyle: {
    position: "absolute",
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    fontSize: getFontSizeXD(12),
    letterSpacing: 2,
    bottom: 40,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    opacity: 0,
  },
};

export default styles;

const styles = {
  textAndIconContainerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  elToRenderContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  languageButtons: {
    position: "fixed",
    marginTop: "10px",
  },
  flag: {
    width: "40px",
    height: "25px",
    borderRadius: "1px",
    margin: "0 5px",
    marginBottom: "30px",
  },
};

export default styles;

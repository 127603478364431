import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Lottie from "react-lottie";
import styles from "./styles";
import images from "../../assets/images/images";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import ScrollLine from "../../components/ScrollLine";
import { isMobile } from "../../utils";
import phoneAnimation from "../../animationsAE/mobileAppItajson.json";
import phoneAnimationEn from "../../animationsAE/mobileAppEng.json";

class WelcomeSection extends Component {
  render() {
    const { t, windowWidth, windowHeight, top } = this.props;
    const {
      root,
      mainSectionContainer,
      welcomeToTextStyle,
      digitalHouseTextStyle,
      payloadTextStyle,
      payloadSmallTextStyle,
      payloadEvidenceTextStyle,
      textsContainerStyle,
      specialCaseTextStyle,
      watchProjectTextStyle,
    } = styles;

    let animationData = phoneAnimation;
    if (window.location.pathname.endsWith("en")) {
      animationData = phoneAnimationEn;
    }

    return (
      <div
        id="WelcomeSection"
        style={Object.assign({}, root, {
          width: windowWidth,
          height: isMobile() ? "auto" : windowHeight * 2,
          top,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: windowWidth,
          })}
        >
          {isMobile() && (
            <div
              style={{
                height: "30%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={images.logoSquare}
                style={{ width: 161, zIndex: 10 }}
              />
            </div>
          )}
          <div style={textsContainerStyle}>
            <AnimatedBorderText style={welcomeToTextStyle}>
              {t("welcomeTo")}
            </AnimatedBorderText>
            <AnimatedBorderText style={digitalHouseTextStyle}>
              {t("digitalHouse")}
            </AnimatedBorderText>
            <div style={payloadTextStyle}>
              {t("payload_part_1")}
              <span style={payloadEvidenceTextStyle}>
                {` ${t("payload_evidence_1")}`}
              </span>
              {` ${t("payload_part_2")}`}
              <span style={payloadEvidenceTextStyle}>
                {` ${t("payload_evidence_2")}`}
              </span>
              {` ${t("payload_part_3")}`}
            </div>
            <div style={payloadSmallTextStyle}>{t("payloadSmall")}</div>
          </div>
          {isMobile() && <div style={{ height: "20%" }} />}
        </div>
        <ScrollLine width={windowWidth} height={windowHeight} />
        {!isMobile() && (
          <>
            <div
              style={{
                position: "absolute",
                left: windowWidth - 320,
                top: windowHeight - 610,
              }}
            >
              <div
                style={{
                  width: 250,
                }}
              >
                <div
                  style={{
                    marginTop: -180,
                    paddingTop: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 20,
                    position: "absolute",
                  }}
                >
                  <div>
                    <img
                      alt="logo La Mia Campania"
                      src={images.logoLmc}
                      srcSet={`${images.logoLmc_2x} 2x`}
                      style={{ width: 140 }}
                    />
                  </div>
                  <div style={{ width: 180, height: 430 }}>
                    <Lottie
                      options={{ animationData: animationData, loop: true }}
                    />
                  </div>
                  <div style={specialCaseTextStyle}>
                    {t("specialCase").toUpperCase()}
                  </div>
                  <a
                    href="https://www.lamiacampania.it"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={watchProjectTextStyle}>
                      {t("watchProject").toUpperCase()}
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                height: 2,
                top: windowHeight - 115,
                left: windowWidth - 350,
                width: 350,
                zIndex: 0,
                backgroundColor: "red",
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default withTranslation("welcomeSection")(WelcomeSection);

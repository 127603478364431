import theme from "../../theme";

const style = {
  backgroundContainer: {
    position: "fixed",
    backgroundColor: theme.purple,
  },
  cubesContainer: {
    opacity: 0,
    position: "fixed",
    top: 0,
    left: 0,
  },
  triggerCubesContainer: {
    transformOrigin: "50% 50%",
  },
  triggerCubeStyle: {
    width: 10,
    height: 10,
    position: "fixed",
    backgroundColor: theme.magenta,
  },
};

export default style;

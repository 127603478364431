import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import icons from "../../assets/images/icons/icons";
import AnimatedLink from "../AnimatedLink";
import config from "../../config";

class BreadcrumbBar extends Component {
  render() {
    const { t, windowHeight } = this.props;
    const {
      root,
      textContainerStyle,
      textStyle,
      iconContainerStyle,
      iconStyle,
      textsMask,
    } = styles;
    const { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } = config;
    return (
      <div
        id="BreadcrumbBar"
        style={Object.assign({}, root, {
          height: windowHeight,
        })}
      >
        <div style={textsMask}>
          <div id="BCTexts" style={textContainerStyle}>
            <div style={textStyle}>- {t("contactUs")}</div>
            <div style={textStyle}>- {t("hiring")}</div>
            <div style={textStyle}>- {t("skill")}</div>
            <div style={textStyle}>- {t("pixxer")}</div>
            <div style={textStyle}>- {t("lmc")}</div>
            <div style={textStyle}>- {t("aboutUs")}</div>
            <div style={textStyle}>- {t("home")}</div>
          </div>
        </div>
        <div style={iconContainerStyle}>
          <div style={iconStyle}>
            <AnimatedLink>
              <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
                <img
                  alt="linkedin"
                  src={icons.linkedin}
                  style={{ width: 19 }}
                />
              </a>
            </AnimatedLink>
          </div>
          <div style={iconStyle}>
            <AnimatedLink>
              <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
                <img
                  alt="instagram"
                  src={icons.instagram}
                  style={{ width: 23 }}
                />
              </a>
            </AnimatedLink>
          </div>
          <div style={iconStyle}>
            <AnimatedLink>
              <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
                <img
                  alt="facebook"
                  src={icons.facebook}
                  style={{ width: 20 }}
                />
              </a>
            </AnimatedLink>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("breadcrumbBar")(BreadcrumbBar);

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import style from "./style";

class HiringCard extends Component {
  render() {
    const { t, position } = this.props;
    const {
      textsContainerStyle,
      cardTitle,
      cardSubTitle,
      iconaSocialStyle,
      cardContainerStyle,
      cardContentStyle,
      cardContentHeadings,
      containerContentStyle,
    } = style;
    const keys = [
      "contenuto1",
      "contenuto2",
      "contenuto3",
      "contenuto4",
      "contenuto5",
      "contenuto6",
    ];

    return (
      <>
        <div style={cardContainerStyle}>
          <div style={textsContainerStyle}>
            <div style={cardTitle}>{t("positions:title")}</div>
            <div style={cardSubTitle}>{t("positions:subTitle")}</div>
          </div>
          <img alt="icon" src={position.icon} style={iconaSocialStyle}></img>
        </div>
        <div style={containerContentStyle}>
          <div style={cardContentStyle}>{t("positions:content")}</div>
        </div>
        <div style={{ display: "flex", padding: "0px 20px" }}>
          <div style={cardContentHeadings}>{t("positions:heading")}</div>
        </div>
        <div style={{ display: "flex", padding: "0px 20px 0 0" }}>
          <ul>
            {keys.map((key) => (
              <li style={cardContentStyle}>{t(`positions:${key}`)}</li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}
export default withTranslation("HiringCard")(HiringCard);

import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "fixed" : "absolute",
    top: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 10,
  },
  leftContainerStyle: {
    paddingTop: theme.defaultMargin,
    paddingBottom: theme.defaultMargin,
    marginLeft: isMobile() ? theme.defaultMargin : 200,
    width: isMobile() ? "100%" : "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  rightContainerStyle: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  iconsContainerStyle: {
    display: "flex",
    flexDirection: "row",
  },
  iconContainerStyle: {
    marginLeft: isMobile() ? 20 : 10,
    marginRight: isMobile() ? 20 : 10,
  },
  addressContainerStyle: {
    marginBottom: 20,
  },
  infoTextStyle: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
  },
  infoTextStyleCertification: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    marginTop: 30,
  },
  termsAndConditionStyle: {
    fontFamily: "Jost, sans-serif",
    color: theme.magenta,
    fontSize: getFontSizeXD(16),
  },
  termsAndConditionLinkStyle: {
    textDecoration: "none",
    color: theme.magenta,
    cursor: "none",
  },
  titleTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontWeight: "bold",
    fontSize: getFontSizeXD(75),
  },
  titleTextEvidenceStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: theme.magenta,
    fontWeight: "bold",
    fontSize: getFontSizeXD(75),
  },
};

export default styles;

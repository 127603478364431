import React, { Component } from "react";
import { TimelineMax, Linear, Power0 } from "gsap";
import { connect } from "react-redux";
import plane from "./../../assets/images/plane@2x.png";
import styles from "./styles";
import { isMobile } from "../../utils";
import config from "../../config";

class Plane extends Component {
  componentDidMount() {
    const { id, duration, clockwise } = this.props;
    const { TRIGGER_DURATION, EXPLOSION_DELAY } = config;
    const opacityInDur = 1;
    const opacityOutDur = 1;
    const hidePlanePosition = duration - opacityOutDur;
    const rotation = clockwise ? -360 : 360;
    let tl = new TimelineMax({
      delay: TRIGGER_DURATION + EXPLOSION_DELAY,
      paused: true
    });
    tl.from(`#${id}`, duration, { rotation, ease: Power0.easeOut }, 0)
      .to(`#${id}`, opacityInDur, { opacity: 1, ease: Linear.easeNone }, 0)
      .to(
        `#${id} .plane`,
        opacityOutDur,
        { width: 0, height: 0, ease: Linear.easeNone },
        hidePlanePosition
      );
    tl.play();
  }

  render() {
    const { planeContainerStyle } = styles;
    const {
      windowWidth,
      windowHeight,
      rotationRadius,
      id,
      clockwise
    } = this.props;
    let planeWidth = 55.5;
    let planeHeight = 50.5;
    if (isMobile()) {
      planeWidth = planeWidth / 2;
      planeHeight = planeHeight / 2;
    }
    const degreesRotation = clockwise ? 45 : -135;
    return (
      <div
        id={id}
        style={Object.assign({}, planeContainerStyle, {
          width: rotationRadius,
          height: rotationRadius,
          top: windowHeight / 4,
          left: windowWidth / 4,
          transform: `rotate(${45 *
            Number(id.substr(id.length - 1, id.length))}deg)`
        })}
      >
        <img
          alt="plane"
          className="plane"
          style={{
            transform: `rotate(${degreesRotation}deg)`,
            width: planeWidth,
            height: planeHeight
          }}
          src={plane}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ dimensions }) => {
  return {
    windowWidth: dimensions.windowWidth,
    windowHeight: dimensions.windowHeight
  };
};

export default connect(mapStateToProps)(Plane);

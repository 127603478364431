import { UPDATE_DIMENSIONS } from "./actions/types";

const INITIAL_STATE = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DIMENSIONS:
      return {
        ...state,
        windowWidth: action.payload.width,
        windowHeight: action.payload.height
      };
    default:
      return state;
  }
};

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import ScrollLine from "../../components/ScrollLine";
import { isMobile } from "../../utils";

class PixxerSection extends Component {
  render() {
    const { t, windowHeight, windowWidth, top } = this.props;
    const {
      root,
      weAreTextStyle,
      pixxerTextStyle,
      textsContainerStyle,
      payloadSmallTextStyle,
      payloadSmallEvidenceTextStyle,
      payloadTextStyle,
      mainSectionContainer,
    } = styles;
    return (
      <div
        id="PixxerSection"
        style={Object.assign({}, root, {
          top,
          width: windowWidth,
          height: isMobile() ? "auto" : windowHeight * 2,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: windowWidth,
          })}
        >
          <div style={textsContainerStyle}>
            <AnimatedBorderText style={weAreTextStyle}>
              {t("weAre")}
            </AnimatedBorderText>
            <AnimatedBorderText style={pixxerTextStyle}>
              {t("pixxer")}
            </AnimatedBorderText>
            <div style={payloadTextStyle}>
              {isMobile() ? t("hungry").toUpperCase() : t("hungry")}
            </div>
            <div
              style={Object.assign(
                {},
                payloadSmallTextStyle,
                payloadSmallEvidenceTextStyle
              )}
            >
              {t("payloadSmall1")}
            </div>
            <div style={payloadSmallTextStyle}>{t("payloadSmall2")}</div>
          </div>
        </div>
        <ScrollLine width={windowWidth} height={windowHeight} />
      </div>
    );
  }
}

export default withTranslation("pixxerSection")(PixxerSection);

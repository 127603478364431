import { Component } from "react";
import { connect } from "react-redux";
import * as dimensionsActions from "./../../reducers/dimensions/actions";
import { isMobile } from "../../utils";

class ResponsiveAppContainer extends Component {
  componentDidMount() {
    if (!isMobile()) {
      window.addEventListener("resize", this.updateWindowDimensions);
    }
  }

  updateWindowDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.props.updateDimensions(width, height);
  };

  render() {
    return this.props.children;
  }
}

export default connect(null, dimensionsActions)(ResponsiveAppContainer);

import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import images from "../../assets/images/images";
import { isMobile } from "../../utils";

class SliceDescription extends Component {
  state = {
    selectedSlice: "",
  };

  componentDidMount() {
    this.setState({
      selectedSlice: this.props.selectedSlice,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedSlice !== this.props.selectedSlice) {
      // setTimeout(() => {
      this.setState({
        selectedSlice: this.props.selectedSlice,
      });
      //}, 500);
    }
  }

  render() {
    const { t } = this.props;
    const { selectedSlice } = this.state;
    const {
      root,
      headerTextStyle,
      descrTextStyle,
      evidenceTextStyle,
      imageStyle,
      logoIctContainerStyle,
    } = styles;
    const titleText = isMobile()
      ? t(`${selectedSlice}Title`).toUpperCase()
      : t(`${selectedSlice}Title`);
    return (
      <div
        style={Object.assign({}, root, {
          height: isMobile() ? "auto" : "100%",
        })}
      >
        {selectedSlice.length > 0 && (
          <Fragment>
            <div style={headerTextStyle}>{titleText}</div>
            <div style={descrTextStyle}>{t(`${selectedSlice}Descr`)}</div>
            <div style={evidenceTextStyle}>{t(`${selectedSlice}Evidence`)}</div>
            {!isMobile() && (
              <img
                alt="selected slice"
                style={imageStyle}
                src={images[`skill_${selectedSlice}`]}
              />
            )}
            {isMobile() && selectedSlice === "webAppDev" && (
              <div style={logoIctContainerStyle}>
                <img
                  alt="logo"
                  src={images.logoICT}
                  style={{ width: 621 / 3, height: 159 / 3 }}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation("skillSection")(SliceDescription);

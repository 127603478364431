import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Lottie from "react-lottie";
import styles from "./styles";
import { animateMenu } from "../../animationUtils";
import config from "../../config";
import MenuList from "../../components/MenuList";
import LogoSmall from "../../components/LogoSmall";
import icons from "../../assets/images/icons/icons";
import AnimatedLink from "../../components/AnimatedLink";
import SendMailButton from "../../components/SendMailButton";
import menuAnimation from "./../../animationsAE/menuAnimation";
import MenuButtonClose from "../../components/MenuButtonClose";
import { isMobile } from "../../utils";
import LogoThumbMobile from "../../components/LogoThumbMobile";

class MenuPage extends Component {
  state = {
    isOpen: false,
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen && !this.props.isOpen) {
      setTimeout(() => animateMenu(false));
      setTimeout(() => {
        this.setState({ isOpen: false });
      }, config.SCROLL_DURATION * 1000);
    } else if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ isOpen: true });
      setTimeout(() => animateMenu(true));
    }
  }

  renderIcons = () => {
    const { iconsContainerStyle, iconContainerStyle } = styles;
    const { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } = config;
    return (
      <div style={iconsContainerStyle}>
        <div style={iconContainerStyle}>
          <AnimatedLink>
            <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
              <img
                alt="facebook"
                src={icons.facebookSmall}
                style={{ width: 11 }}
              />
            </a>
          </AnimatedLink>
        </div>
        <div style={iconContainerStyle}>
          <AnimatedLink>
            <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
              <img
                alt="instagram"
                src={icons.instagramSmall}
                style={{ width: 22 }}
              />
            </a>
          </AnimatedLink>
        </div>
        <div style={iconContainerStyle}>
          <AnimatedLink>
            <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
              <img
                alt="linkedin"
                src={icons.linkedinSmall}
                style={{ width: 20 }}
              />
            </a>
          </AnimatedLink>
        </div>
      </div>
    );
  };

  renderInfo = () => {
    const { t } = this.props;
    const {
      addressContainerStyle,
      infoTextStyle,
      infoTextStyleCertification,
      termsAndConditionStyle,
      termsAndConditionLinkStyle,
    } = styles;
    return (
      <div>
        <div style={infoTextStyle}>{t("company")}</div>
        <div style={addressContainerStyle}>
          <div style={infoTextStyle}>{t("address1")}</div>
          <div style={infoTextStyle}>{t("address2")}</div>
          <div style={infoTextStyle}>{t("vat")}</div>
          <div style={infoTextStyleCertification}>{t("certification")}</div>
        </div>
        <div style={infoTextStyle}>{t("designedBy")}</div>
        <div style={infoTextStyle}>{t("copyright")}</div>
        <div style={termsAndConditionStyle}>
          <a
            href="https://www.iubenda.com/privacy-policy/13194507/legal"
            target="_blank"
            rel="noopener noreferrer"
            style={termsAndConditionLinkStyle}
          >
            {t("termsAndConditions")}
          </a>
        </div>
      </div>
    );
  };

  renderLogo = () => {
    const { toggleMenu } = this.props;
    if (isMobile()) {
      return <LogoThumbMobile toggleMenu={toggleMenu} />;
    } else {
      return <LogoSmall />;
    }
  };

  render() {
    const {
      root,
      leftContainerStyle,
      rightContainerStyle,
      titleTextStyle,
      titleTextEvidenceStyle,
    } = styles;
    const {
      t,
      windowWidth,
      windowHeight,
      toggleMenu,
      loadSection,
    } = this.props;
    const { isOpen } = this.state;
    if (!isOpen) {
      return <div />;
    }
    return (
      <div
        id="MenuPage1"
        style={Object.assign({}, root, {
          width: windowWidth,
          height: isMobile() ? "100%" : windowHeight,
          left: windowWidth,
        })}
      >
        <div style={leftContainerStyle}>
          {this.renderLogo()}
          {isMobile() && <MenuButtonClose onClick={toggleMenu} />}
          <MenuList loadSection={loadSection} toggleMenu={toggleMenu} />

          {this.renderIcons()}
          {this.renderInfo()}
        </div>
        {!isMobile() && (
          <div style={rightContainerStyle}>
            <MenuButtonClose onClick={toggleMenu} />
            <div style={{ maxWidth: 500, maxHeight: 500 }}>
              <Lottie options={{ animationData: menuAnimation, loop: true }} />
            </div>
            <div style={{ marginRight: 80 }}>
              <div style={titleTextStyle}>{t("takeASlice")}</div>
              <div style={titleTextStyle}>
                {t("of")}
                <span style={titleTextEvidenceStyle}> {t("pixxa")}</span>
              </div>
            </div>
            <SendMailButton style={{ marginBottom: 80, marginRight: 80 }} />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation("menu")(MenuPage);

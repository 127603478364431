const styles = {
  cursorSquare: {
    position: "fixed",
    transform: "translateX(-50%) translateY(-50%)",
    pointerEvents: "none",
    zIndex: 100002,
    height: 30,
    width: 30,
    border: "1px solid white",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  cursorPoint: {
    position: "fixed",
    transform: "translateX(-50%) translateY(-50%)",
    pointerEvents: "none",
    zIndex: 100001,
    width: 4,
    height: 4,
    backgroundColor: "#00e7ff"
  }
};

export default styles;

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import AnimatedBorderText from "../../components/AnimatedBorderText";
import images from "../../assets/images/images";
import { isMobile } from "../../utils";
import config from "../../config";
import AnimatedLink from "../../components/AnimatedLink";
import icons from "../../assets/images/icons/icons";

class ContactUsSection extends Component {
  renderIcons = () => {
    const { iconsContainerStyle, iconContainerStyle } = styles;
    const { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } = config;
    return (
      <div style={iconsContainerStyle}>
        <div style={iconContainerStyle}>
          <AnimatedLink>
            <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
              <img
                alt="facebook"
                src={icons.facebookSmall}
                style={{ width: 11 }}
              />
            </a>
          </AnimatedLink>
        </div>
        <div style={iconContainerStyle}>
          <AnimatedLink>
            <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
              <img
                alt="instagram"
                src={icons.instagramSmall}
                style={{ width: 22 }}
              />
            </a>
          </AnimatedLink>
        </div>
        <div style={iconContainerStyle}>
          <AnimatedLink>
            <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
              <img
                alt="linkedin"
                src={icons.linkedinSmall}
                style={{ width: 20 }}
              />
            </a>
          </AnimatedLink>
        </div>
      </div>
    );
  };
  render() {
    const { t, windowHeight, windowWidth, top } = this.props;
    const {
      root,
      takeASliceOfTextStyle,
      pixxaTextStyle,
      textsContainerStyle,
      mainSectionContainer,
      footerStyle,
      footerStyleMobile,
      companyStyle,
      companyStyleMobile,
      certificationStyle,
      certificationStyleMobile,
    } = styles;
    return (
      <div
        id="ContactUsSection"
        style={Object.assign({}, root, {
          top,
          width: windowWidth,
          height: isMobile() ? "auto" : windowHeight * 2,
        })}
      >
        {/*{isMobile() && (
          <Fragment>
            <div style={bigCircleStyle} />
            <div style={smallCircleStyle} />
          </Fragment>
        )}*/}
        <div
          style={Object.assign({}, mainSectionContainer, {
            top,
            height: isMobile() ? "auto" : windowHeight,
            width: windowWidth,
          })}
        >
          <img
            alt="contact us"
            src={images.contactUs}
            style={{ width: 278.5, zIndex: 10 }}
          />
          <div style={textsContainerStyle}>
            <AnimatedBorderText style={takeASliceOfTextStyle}>
              {t("takeASliceOf")}
            </AnimatedBorderText>
            <AnimatedBorderText style={pixxaTextStyle}>
              {t("pixxa")}
            </AnimatedBorderText>
          </div>
          <div style={{ marginBottom: 14 }}>
            <div style={isMobile() ? footerStyleMobile : footerStyle}>
              {t("footer")}
            </div>
            <div
              style={isMobile() ? certificationStyleMobile : certificationStyle}
            />
            <div style={isMobile() ? companyStyleMobile : companyStyle}>
              {`${t("company")} - ${t("address1")} ${t("address2")} - ${t(
                "vat"
              )}`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("contactUsSection")(ContactUsSection);

import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: isMobile() ? "center" : "flex-start",
    width: isMobile() ? "auto" : "25%",
    marginRight: isMobile() ? 0 : 20,
    paddingLeft: isMobile() ? theme.defaultMargin : 0,
    paddingRight: isMobile() ? theme.defaultMargin : 0,
  },
  headerTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: theme.magenta,
    fontSize: getFontSizeXD(33),
    fontWeight: 900,
    marginBottom: isMobile() ? 20 : 10,
    textAlign: isMobile() ? "center" : "left",
    marginTop: isMobile() ? 30 : 0,
  },
  descrTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(20),
    marginBottom: 10,
    textAlign: isMobile() ? "center" : "left",
  },
  evidenceTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: theme.lightBlue,
    fontSize: getFontSizeXD(20),
    textAlign: isMobile() ? "center" : "left",
  },
  imageStyle: {
    marginTop: 20,
    width: 100,
  },
  logoIctContainerStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.defaultMargin,
  },
};

export default styles;

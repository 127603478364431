import React from "react";
import Lottie from "react-lottie";
import styles from "./styles";
import phoneAnimation from "../../animationsAE/mobileAppItajson.json";
import phoneAnimationEn from "../../animationsAE/mobileAppEng.json";
import { useLocation } from "react-router-dom";

const LmcPhone = () => {
  const { appContainerStyle, lmcAnimationMaskStyle } = styles;
  const location = useLocation();
  const animationData = location.pathname.endsWith("/en")
    ? phoneAnimationEn
    : phoneAnimation;

  return (
    <div style={appContainerStyle}>
      <div
        style={{
          marginTop: 100,
          paddingTop: 34,
        }}
      >
        <div style={{ ...lmcAnimationMaskStyle, width: 270, height: 600 }}>
          <Lottie
            options={{
              animationData: animationData,
              loop: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LmcPhone;

import i18next from "i18next";
import landingPage_it from "./translations/it/landingPage.json";
import landingPage_en from "./translations/en/landingPage.json";
import metas_it from "./translations/it/metas.json";
import metas_en from "./translations/en/metas.json";
import welcomeSection_it from "./translations/it/welcomeSection.json";
import welcomeSection_en from "./translations/en/welcomeSection.json";
import breadcrumbBar_it from "./translations/it/breadcrumbBar.json";
import breadcrumbBar_en from "./translations/en/breadcrumbBar.json";
import menu_it from "./translations/it/menu.json";
import menu_en from "./translations/en/menu.json";
import sponsor_it from "./translations/it/sponsor.json";
import sponsor_en from "./translations/en/sponsor.json";
import aboutUsSection_it from "./translations/it/aboutUsSection.json";
import aboutUsSection_en from "./translations/en/aboutUsSection.json";
import pixxerSection_it from "./translations/it/pixxerSection.json";
import pixxerSection_en from "./translations/en/pixxerSection.json";
import lmcSection_it from "./translations/it/lmcSection.json";
import lmcSection_en from "./translations/en/lmcSection.json";
import skillSection_it from "./translations/it/skillSection.json";
import skillSection_en from "./translations/en/skillSection.json";
import contactUsSection_it from "./translations/it/contactUsSection.json";
import contactUsSection_en from "./translations/en/contactUsSection.json";
import hiringSection_it from "./translations/it/hiringSection.json";
import hiringSection_en from "./translations/en/hiringSection.json";
import positionTitle_it from "./translations/it/positionsTitle.json";
import positionTitle_en from "./translations/en/positionsTitle.json";
import position_en from "./translations/en/positions.json";
import position_it from "./translations/it/positions.json";
import email_it from "./translations/it/emailInstruction.json";
import email_en from "./translations/en/emailInstruction.json";
import slice_it from "./translations/it/slice.json";
import slice_en from "./translations/en/slice.json";

// TRANSLATIONS

i18next.init({
  interpolation: { escapeValue: false },
  lng: "it",
  resources: {
    it: {
      sponsor: sponsor_it,
      landingPage: landingPage_it,
      metas: metas_it,
      welcomeSection: welcomeSection_it,
      breadcrumbBar: breadcrumbBar_it,
      menu: menu_it,
      aboutUsSection: aboutUsSection_it,
      pixxerSection: pixxerSection_it,
      lmcSection: lmcSection_it,
      skillSection: skillSection_it,
      contactUsSection: contactUsSection_it,
      hiringSection: hiringSection_it,
      positionsTitle: positionTitle_it,
      positions: position_it,
      emailInstruction: email_it,
      slice: slice_it,
    },
    en: {
      sponsor: sponsor_en,
      landingPage: landingPage_en,
      metas: metas_en,
      aboutUsSection: aboutUsSection_en,
      breadcrumbBar: breadcrumbBar_en,
      menu: menu_en,
      welcomeSection: welcomeSection_en,
      lmcSection: lmcSection_en,
      skillSection: skillSection_en,
      pixxerSection: pixxerSection_en,
      contactUsSection: contactUsSection_en,
      hiringSection: hiringSection_en,
      positionsTitle: positionTitle_en,
      positions: position_en,
      emailInstruction: email_en,
      slice: slice_en,
    },
  },
});

export default i18next;

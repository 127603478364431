import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import AnimatedLink from "../AnimatedLink";
import { isMobile } from "../../utils";
import icons from "../../assets/images/icons/icons";

class MenuButtonClose extends Component {
  renderButton = () => {
    const { textStyle } = styles;
    const { t } = this.props;
    if (isMobile()) {
      return <img alt="Close menu" src={icons.menuClose} width={18} />;
    } else {
      return (
        <AnimatedLink style={textStyle}>
          {t("menuClose")} <span style={{ fontWeight: 900 }}>X</span>
        </AnimatedLink>
      );
    }
  };
  render() {
    const { root } = styles;
    const { onClick } = this.props;
    return (
      <div style={root} onClick={() => onClick()}>
        {this.renderButton()}
      </div>
    );
  }
}

export default withTranslation("landingPage")(MenuButtonClose);

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LandingPage from "./pages/LandingPage";
import config from "./config";
import { isMobile } from "./utils";
import LandingPageMobile from "./pages/LandingPageMobile";
import i18n from "./i18n";

function LanguageSwitcher() {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location ? location.pathname : "/";
    if (currentPath.endsWith("/en")) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("it");
    }
  }, [location]);

  return null;
}

export default function AppRouter() {
  const { t } = useTranslation();

  return (
    <Router>
      <LanguageSwitcher />
      <div className="app">
        <Helmet>
          <title>{config.APP_NAME}</title>
          <meta charSet="utf-8" />
          <meta name="description" content={t("metas:description")} />
        </Helmet>
        <Switch>
          <Route
            path="/"
            exact
            component={isMobile() ? LandingPageMobile : LandingPage}
          />
          <Route
            path="/en"
            exact
            component={isMobile() ? LandingPageMobile : LandingPage}
          />
        </Switch>
      </div>
    </Router>
  );
}

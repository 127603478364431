import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./styles";
import AnimatedLink from "../AnimatedLink";
import config from "../../config";
import { animateMenuButton } from "../../animationUtils";
import { isMobile } from "../../utils";
import { withRouter } from "react-router-dom";
import icons from "../../assets/images/icons/icons";

class MenuButton extends Component {
  state = {
    canRender: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        canRender: true,
      });
    }, (config.TRIGGER_DURATION + config.EXPLOSION_DURATION) * 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.canRender && this.state.canRender) {
      animateMenuButton(true);
    }
  }

  renderButton = () => {
    const { textStyle } = styles;
    const { t } = this.props;
    if (isMobile()) {
      return <img alt="open menu" src={icons.menuOpen} style={{ width: 30 }} />;
    } else {
      return <AnimatedLink style={textStyle}>{t("menu")}</AnimatedLink>;
    }
  };

  render() {
    const { root } = styles;
    const { onClick } = this.props;
    if (!this.state.canRender) {
      return <div />;
    }
    return (
      <div id="MenuButton" style={root} onClick={() => onClick(true)}>
        {this.renderButton()}
      </div>
    );
  }
}

export default withRouter(withTranslation("landingPage")(MenuButton));

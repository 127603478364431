import img0 from "./images/img_0.png";
import img1 from "./images/img_1.png";

export default {
  v: "5.6.5",
  fr: 29.9700012207031,
  ip: 0,
  op: 1500.00006109625,
  w: 375,
  h: 812,
  nm: "Composizione 1",
  ddd: 0,
  assets: [
    { id: "image_0", w: 938, h: 930, p: img0, e: 0 },
    { id: "image_1", w: 1557, h: 1555, p: img1, e: 0 }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Livello forma 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [187.5, 406, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [],
      ip: 0,
      op: 480.000019550801,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "Oval Copy 5",
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 50, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 299,
              s: [-50]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 600,
              s: [161]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 900,
              s: [230.394]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 1196,
              s: [165.276]
            },
            { t: 1484.00006044456, s: [-50] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [33, 116, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 301,
              s: [198.333, 134, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 600,
              s: [319.212, 125.451, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 901,
              s: [340.423, 274.233, 0],
              to: [0, 0, 0],
              ti: [133.026, 76.36, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 1196,
              s: [198.202, 84.168, 0],
              to: [-91.237, -52.372, 0],
              ti: [0, 0, 0]
            },
            { t: 1484.00006044456, s: [33, 116, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [469, 465, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [18, 18, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 301,
              s: [13, 13, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 600,
              s: [25.012, 25.012, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 900,
              s: [17.705, 17.705, 100]
            },
            { t: 1484.00006044456, s: [18, 18, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 1511.00006154429,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "Oval Copy 10",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 50, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 301,
              s: [-60]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 598,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 1197,
              s: [114]
            },
            { t: 1484.00006044456, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 1,
              s: [328, 640, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 301,
              s: [206.667, 622, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 600,
              s: [94.667, 594.549, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 901,
              s: [75.45, 378.025, 0],
              to: [0, 0, 0],
              ti: [-115.775, -60.357, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 1197,
              s: [186.343, 635.036, 0],
              to: [91.425, 47.662, 0],
              ti: [0, 0, 0]
            },
            { t: 1484.00006044456, s: [328, 640, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [778.5, 777.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 1,
              s: [18, 18, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 301,
              s: [16, 16, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 600,
              s: [8.746, 8.746, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 900,
              s: [18.207, 18.207, 100]
            },
            { t: 1484.00006044456, s: [18, 18, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 1560.0000635401,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

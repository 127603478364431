import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import { Linear, TimelineMax } from "gsap";
import styles from "./styles";
import theme from "../../theme";
import { animateMouse, animateSlice } from "../../animationUtils";
import {
  generateRandomFromRange,
  getComputedScaleXY,
  isMobile
} from "../../utils";

class InteractivePizza extends Component {
  componentDidMount() {
    let rects = $(".sliceSquare");
    rects.each(i => {
      const computedScale = getComputedScaleXY(rects[i]);
      let randomDistance = generateRandomFromRange(30, 10, true);
      let randomDuration = generateRandomFromRange(2, 5, true);
      let tl = new TimelineMax({
        repeat: -1
      });
      tl.to(
        rects[i],
        randomDuration,
        {
          x: computedScale.x + randomDistance,
          y: computedScale.y + randomDistance,
          ease: Linear.easeNone
        },
        0
      );
      tl.to(
        rects[i],
        randomDuration,
        {
          x: computedScale.x,
          y: computedScale.y + randomDistance * 2,
          ease: Linear.easeNone
        },
        randomDuration
      );
      tl.to(
        rects[i],
        randomDuration,
        {
          x: computedScale.x - randomDistance,
          y: computedScale.y + randomDistance,
          ease: Linear.easeNone
        },
        randomDuration * 2
      );
      tl.to(
        rects[i],
        randomDuration,
        { x: computedScale.x, y: computedScale.y, ease: Linear.easeNone },
        randomDuration * 3
      );
      tl.play();
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedSlice !== this.props.selectedSlice) {
      animateSlice(prevProps.selectedSlice, this.props.selectedSlice);
    }
  }

  handleSliceClick = slice => {
    this.props.selectSlice(slice);
  };

  handleSliceEnter = slice => {
    const { t } = this.props;
    const { tooltipTextStyle } = styles;
    const cursor = document.getElementById("cursor1");
    ReactDOM.render(
      <div style={tooltipTextStyle}>{t(`${slice}Title`)}</div>,
      cursor
    );
    animateMouse(10, 10, "inline-table");
  };

  handleSliceLeave = () => {
    const cursor = document.getElementById("cursor1");
    ReactDOM.render("", cursor);
    animateMouse();
  };

  renderPizzaWeb = () => {
    const { b, c, d } = styles;
    const { selectedSlice } = this.props;
    return (
      <div style={{ width: 550 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="550"
          height="550"
          viewBox="0 0 550 550"
        >
          <g transform="translate(-51 -51)">
            <g
              id="officeEnvironment"
              onMouseEnter={() => this.handleSliceEnter("officeEnvironment")}
              onMouseLeave={this.handleSliceLeave}
              onClick={() => this.handleSliceClick("officeEnvironment")}
              transform="translate(153.118 111.211)"
            >
              <path
                style={Object.assign({}, b, {
                  fill:
                    selectedSlice === "officeEnvironment"
                      ? theme.lightBlue
                      : "transparent"
                })}
                d="M3512.556,5189.7a214.479,214.479,0,0,0-196.8,83.8l171.157,128.976,65.364-202.669.427-1.341A211.908,211.908,0,0,0,3512.556,5189.7Z"
                transform="translate(-3310.689 -5187.185)"
              />
              <path
                style={c}
                d="M3309.825,5269.508a214.509,214.509,0,0,1,241.587-69.874"
                transform="translate(-3309.825 -5187.018)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(109 59)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(160 120)"
              />
            </g>
            <g
              id="userExperience"
              onMouseEnter={() => this.handleSliceEnter("userExperience")}
              onMouseLeave={this.handleSliceLeave}
              onClick={() => this.handleSliceClick("userExperience")}
              transform="translate(329.341 122.487)"
            >
              <path
                style={Object.assign({}, b, {
                  fill:
                    selectedSlice === "userExperience"
                      ? theme.lightBlue
                      : "transparent"
                })}
                d="M3730.421,5404.219v.008H3516.126l65.364-202.669.427-1.341C3668.081,5227.979,3730.421,5308.821,3730.421,5404.219Z"
                transform="translate(-3516.126 -5200.217)"
              />
              <path
                style={c}
                d="M3592.646,5201.787a214.378,214.378,0,0,1,141.688,201.687c0,.324,0,.658-.009.982"
                transform="translate(-3527.282 -5200.446)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(119 69)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(60 140)"
              />
            </g>
            <g
              id="socialMediaMarketing"
              onMouseEnter={() => this.handleSliceEnter("socialMediaMarketing")}
              onMouseLeave={this.handleSliceLeave}
              onClick={() => this.handleSliceClick("socialMediaMarketing")}
              transform="translate(151.624 326.498)"
            >
              <path
                style={Object.assign({}, b, {
                  fill:
                    selectedSlice === "socialMediaMarketing"
                      ? theme.lightBlue
                      : "transparent"
                })}
                d="M3554.058,5642.478a214.36,214.36,0,0,1-241.032-77.613l173.49-125.816,66.5,200.286Z"
                transform="translate(-3308.798 -5439.048)"
              />
              <path
                style={c}
                d="M3552.294,5661.331a214.433,214.433,0,0,1-244.218-71.4"
                transform="translate(-3308.076 -5461.045)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(119 69)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(60 140)"
              />
            </g>
            <g
              id="webAppDev"
              onMouseEnter={() => this.handleSliceEnter("webAppDev")}
              onMouseLeave={this.handleSliceLeave}
              onClick={() => this.handleSliceClick("webAppDev")}
              transform="translate(107.794 193.703)"
            >
              <path
                style={Object.assign({}, b, {
                  fill:
                    selectedSlice === "webAppDev"
                      ? theme.lightBlue
                      : "transparent"
                })}
                d="M3479.549,5417.035l-173.49,125.816a214.341,214.341,0,0,1,2.332-254.793Z"
                transform="translate(-3258.002 -5284.24)"
              />
              <path
                style={c}
                d="M3300.6,5545.266a214.322,214.322,0,0,1,1.495-261.678"
                transform="translate(-3256.766 -5283.588)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(119 69)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(60 140)"
              />
            </g>
            <g
              id="graphicAndVideo"
              onMouseEnter={() => this.handleSliceEnter("graphicAndVideo")}
              onMouseLeave={this.handleSliceLeave}
              onClick={() => this.handleSliceClick("graphicAndVideo")}
              transform="translate(329.341 326.498)"
            >
              <path
                style={Object.assign({}, b, {
                  fill:
                    selectedSlice === "graphicAndVideo"
                      ? theme.lightBlue
                      : "transparent"
                })}
                d="M3730.421,5439.048H3516.126l66.5,200.286,1.042,3.144c85.259-28.292,146.753-108.672,146.753-203.43Z"
                transform="translate(-3516.126 -5439.048)"
              />
              <path
                style={c}
                d="M3734.519,5439.048c-.41,92.016-58.812,170.329-140.543,200.286"
                transform="translate(-3527.476 -5439.048)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(119 69)"
              />
              <rect
                className="sliceSquare"
                style={d}
                width="11"
                height="11"
                transform="translate(60 140)"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  };

  renderPizzaMobile = () => {
    const { amob, bmob, cmob, dmob, emob, fmob } = styles;
    const { selectedSlice } = this.props;
    return (
      <div
        style={{
          marginTop: 20,
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="340"
          height="340"
          viewBox="0 0 340 340"
        >
          <g transform="translate(-51.43 -51.43)">
            <g style={amob} transform="translate(51.43 51.43)">
              <rect style={emob} width="340" height="340" />
              <rect style={fmob} x="0.5" y="0.5" width="339" height="339" />
            </g>
            <g transform="translate(86.204 88.321)">
              <g
                id="officeEnvironment"
                onClick={() => this.handleSliceClick("officeEnvironment")}
                transform="translate(28.088)"
              >
                <path
                  style={Object.assign({}, bmob, {
                    fill:
                      selectedSlice === "officeEnvironment"
                        ? theme.lightBlue
                        : "transparent"
                  })}
                  d="M3437.715,5189.115a132.916,132.916,0,0,0-121.96,51.931l106.069,79.929,40.507-125.6.264-.831A131.295,131.295,0,0,0,3437.715,5189.115Z"
                  transform="translate(-3312.616 -5187.558)"
                />
                <path
                  style={cmob}
                  d="M3309.825,5238.138a132.935,132.935,0,0,1,149.716-43.3"
                  transform="translate(-3309.825 -5187.018)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(115.434 29.979)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(76.392 72.12)"
                />
              </g>
              <g
                id="userExperience"
                onClick={() => this.handleSliceClick("userExperience")}
                transform="translate(137.296 6.987)"
              >
                <path
                  style={Object.assign({}, bmob, {
                    fill:
                      selectedSlice === "userExperience"
                        ? theme.lightBlue
                        : "transparent"
                  })}
                  d="M3648.928,5326.64v.005h-132.8l40.507-125.6.265-.831A132.856,132.856,0,0,1,3648.928,5326.64Z"
                  transform="translate(-3516.126 -5200.217)"
                />
                <path
                  style={cmob}
                  d="M3592.646,5201.788a132.853,132.853,0,0,1,87.806,124.988c0,.2,0,.408-.005.608"
                  transform="translate(-3552.139 -5200.957)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(39.69 84.344)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(98.563 102.316)"
                />
              </g>
              <g
                id="socialMediaMarketing"
                onClick={() => this.handleSliceClick("socialMediaMarketing")}
                transform="translate(27.162 133.417)"
              >
                <path
                  style={Object.assign({}, bmob, {
                    fill:
                      selectedSlice === "socialMediaMarketing"
                        ? theme.lightBlue
                        : "transparent"
                  })}
                  d="M3462.4,5565.117a132.842,132.842,0,0,1-149.371-48.1l107.514-77.971,41.211,124.121Z"
                  transform="translate(-3310.406 -5439.048)"
                />
                <path
                  style={cmob}
                  d="M3459.422,5634.178a132.888,132.888,0,0,1-151.346-44.25"
                  transform="translate(-3308.076 -5510.057)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(55.008 83.098)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(107.684 70.084)"
                />
              </g>
              <g
                id="webAppDev"
                onClick={() => this.handleSliceClick("webAppDev")}
                transform="translate(0 51.121)"
              >
                <path
                  style={Object.assign({}, bmob, {
                    fill:
                      selectedSlice === "webAppDev"
                        ? theme.lightBlue
                        : "transparent"
                  })}
                  d="M3398.054,5367.987l-107.515,77.971a132.831,132.831,0,0,1,1.446-157.9Z"
                  transform="translate(-3260.757 -5285.691)"
                />
                <path
                  style={cmob}
                  d="M3283.928,5445.755a132.819,132.819,0,0,1,.927-162.167"
                  transform="translate(-3256.766 -5283.588)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(85.888 81.111)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(27.015 96.604)"
                />
              </g>
              <g
                id="graphicAndVideo"
                onClick={() => this.handleSliceClick("graphicAndVideo")}
                transform="translate(137.296 133.417)"
              >
                <path
                  style={Object.assign({}, bmob, {
                    fill:
                      selectedSlice === "graphicAndVideo"
                        ? theme.lightBlue
                        : "transparent"
                  })}
                  d="M3648.928,5439.048h-132.8l41.211,124.121.646,1.948a132.853,132.853,0,0,0,90.946-126.069Z"
                  transform="translate(-3516.126 -5439.048)"
                />
                <path
                  style={cmob}
                  d="M3681.073,5439.048a132.853,132.853,0,0,1-87.1,124.121"
                  transform="translate(-3552.765 -5439.048)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(29.155 35.999)"
                />
                <rect
                  className="sliceSquare"
                  style={dmob}
                  width="6.817"
                  height="6.817"
                  transform="translate(98.563 21.126)"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  };

  render() {
    return isMobile() ? this.renderPizzaMobile() : this.renderPizzaWeb();
  }
}

export default withTranslation("skillSection")(InteractivePizza);

import React, { Component } from "react";
import images from "../../assets/images/images";
import styles from "./styles";

class LogoSmall extends Component {
  render() {
    const { root } = styles;
    return <img alt="logo" src={images.logoSmall} style={root} />;
  }
}

export default LogoSmall;

import { combineReducers } from "redux";
import dimensionsReducer from "./dimensions";
import mouseReducer from "./mouse";
import menuReducer from "./menu";
import skillReducer from "./skill";

export default combineReducers({
  dimensions: dimensionsReducer,
  mouse: mouseReducer,
  menu: menuReducer,
  skill: skillReducer
});

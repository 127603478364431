import { SELECT_SLICE } from "./actions/types";

const INITIAL_STATE = {
  selectedSlice: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_SLICE:
      return { ...state, selectedSlice: action.slice };
    default:
      return state;
  }
};

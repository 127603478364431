import React, { Component } from "react";
import { connect } from "react-redux";
import theme from "../../theme";
import config from "../../config";
import {
  generateRandomFromRange,
  isMobile,
  randomRotationDirection
} from "../../utils";
import { Linear, TimelineMax } from "gsap";
import $ from "jquery";

class AnimatedPixels extends Component {
  componentDidMount() {
    const {
      SQUARES_MODULE_FACTOR,
      EXPLOSION_DELAY,
      MIN_DUR_FAST_PIXELS,
      MAX_DUR_FAST_PIXELS,
      TRIGGER_DURATION
    } = config;

    let opacityDur = 1;
    let opacityDelay = 1;
    let rects = $(".squares rect");
    let rectsFront = $(".squares2 rect");
    let allRects = $.merge(rects, rectsFront);
    let delay = EXPLOSION_DELAY + TRIGGER_DURATION;
    const { windowWidth, windowHeight } = this.props;

    let tl = new TimelineMax({
      delay,
      paused: true
    });

    let opacityTl = new TimelineMax({
      repeat: -1,
      paused: true,
      delay: delay
    });

    allRects.each(i => {
      if (i % SQUARES_MODULE_FACTOR === 0) {
        let randomOpacityStartDelay = generateRandomFromRange(1, 0);
        let randomOpacityDelay = generateRandomFromRange(
          randomOpacityStartDelay + opacityDelay,
          0.2
        );
        let randomRotationDur = generateRandomFromRange(
          MAX_DUR_FAST_PIXELS,
          MIN_DUR_FAST_PIXELS
        );
        tl.from(
          rects[i],
          randomRotationDur,
          {
            rotation: randomRotationDirection(),
            svgOrigin: `${windowWidth / 2} ${windowHeight / 2}`,
            repeat: -1,
            ease: Linear.easeNone
          },
          0
        );

        let randomOpacityDuration = generateRandomFromRange(opacityDur, 0.2);

        opacityTl.from(
          rects[i],
          randomOpacityDuration,
          {
            opacity: 0,
            delay: randomOpacityStartDelay,
            ease: Linear.easeNone
          },
          0
        );

        opacityTl.to(
          rects[i],
          randomOpacityDuration,
          {
            delay: randomOpacityDelay,
            opacity: 0,
            ease: Linear.easeNone
          },
          0
        );
      } else {
        tl.from(
          rects[i],
          200,
          {
            rotation: randomRotationDirection(),
            svgOrigin: `${windowWidth / 2} ${windowHeight / 2}`,
            repeat: -1,
            ease: Linear.easeNone
          },
          0
        );
      }
    });

    tl.play();
    opacityTl.play();
  }

  generateSquares = isFrontLayer => {
    const { magenta } = theme;
    const { windowWidth, windowHeight } = this.props;
    const {
      FRONT_SQUARES_QTY,
      BACK_SQUARES_QTY,
      FRONT_SQUARE_MAX,
      FRONT_SQUARE_MIN,
      BACK_SQUARE_MAX,
      BACK_SQUARE_MIN
    } = config;

    let minX = 15;
    let maxX = windowWidth - 15;

    let minY = 15;
    let maxY = windowHeight - 15;

    let maxDimensionFront = isMobile() ? 10 : FRONT_SQUARE_MAX;
    let minDimensionFront = isMobile() ? 5 : FRONT_SQUARE_MIN;

    let minDimension = isFrontLayer ? minDimensionFront : BACK_SQUARE_MIN;
    let maxDimension = isFrontLayer ? maxDimensionFront : BACK_SQUARE_MAX;

    let quantity = isFrontLayer ? FRONT_SQUARES_QTY : BACK_SQUARES_QTY;
    let rects = [];
    let i = 0;
    while (i < quantity) {
      i++;
      let randomX = generateRandomFromRange(maxX, minX, true);
      let randomY = generateRandomFromRange(maxY, minY, true);
      let randomDimension = generateRandomFromRange(
        maxDimension,
        minDimension,
        true
      );
      rects.push({
        x: randomX,
        y: randomY,
        dimension: randomDimension
      });
    }
    return rects.map((el, i) => {
      return (
        <rect
          key={i}
          width={el.dimension}
          height={el.dimension}
          fill={magenta}
          x={el.x}
          y={el.y}
        />
      );
    });
  };

  render() {
    const { front, windowWidth, windowHeight } = this.props;
    let width = windowWidth;
    let height = windowHeight;
    let left = 0;
    let top = 0;
    return (
      <svg
        id={front ? "svg2" : "svg1"}
        className="squaresContainer"
        style={{
          width,
          height,
          position: "absolute",
          opacity: 0,
          left,
          top
        }}
      >
        <g className={front ? "squares2" : "squares"}>
          {this.generateSquares(front)}
        </g>
      </svg>
    );
  }
}

const mapStateToProps = ({ dimensions }) => {
  return {
    windowWidth: dimensions.windowWidth,
    windowHeight: dimensions.windowHeight
  };
};

export default connect(mapStateToProps)(AnimatedPixels);

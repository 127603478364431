import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textsContainerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: isMobile() ? "center" : "flex-start",
  },
  laMiaTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 38 : 70),
    color: "white",
    zIndex: 1,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
    textAlign: isMobile() ? "left" : "center",
  },
  campaniaTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 35 : 85),
    color: theme.magenta,
    zIndex: 1,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
    textAlign: isMobile() ? "left" : "center",
  },
  payoffTextStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: isMobile() ? getFontSizeXD(28) : getFontSizeXD(34),
    fontWeight: "bold",
    color: "white",
    letterSpacing: 0,
    marginTop: isMobile() ? 0 : 20,
    marginBottom: 54,
    zIndex: 1,
    width: isMobile() ? "70%" : "100%",
    textAlign: "center",
  },
  payoffEvidenceTextStyle: {
    color: theme.lightBlue,
  },
  payloadTextStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(21),
    color: "white",
    letterSpacing: 0,
    marginTop: 16,
    zIndex: 1,
    width: isMobile() ? "70%" : "60%",
    textAlign: isMobile() ? "center" : "left",
  },
  payloadTextEvidenceStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(24),
    fontWeight: "bold",
    color: theme.lightBlue,
    letterSpacing: 0,
    marginTop: 16,
    zIndex: 1,
    width: isMobile() ? "70%" : "60%",
    textAlign: isMobile() ? "center" : "left",
  },
  imageStyle: {
    width: 450,
    position: "absolute",
    top: 0,
    left: 0,
  },
  videoContainerStyle: { position: "absolute", left: "50%", marginTop: 30 },
  contentContainer: {
    display: "flex",
    flexDirection: isMobile() ? "column" : "row",
    justifyContent: "space-between",
    width: "100%",
  },
  sideContentStyle: {
    width: isMobile() ? "100%" : "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  goToSiteTextStyle: {
    fontSize: getFontSizeXD(21),
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontWeight: "700",
  },
  rightArrowStyle: {
    width: 28,
  },
  textAndIconContainerStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: isMobile() ? 10 : 0,
    paddingRight: 10,
    marginBottom: 40,
  },
  animatedLinkGoToSiteStyle: {
    display: "inline-block",
    whiteSpace: "nowrap",
  },
  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 400,
    height: 400,
    borderRadius: 200,
    position: "absolute",
    top: 20,
    right: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 250,
    height: 250,
    borderRadius: 135,
    position: "absolute",
    top: 400,
    left: -50,
  },
};

export default styles;

import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
    alignContent: isMobile() ? "none" : "center",
  },
  textsContainerStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: isMobile() ? "center" : "flex-start",
  },
  iconaSocialStyle: {
    width: 35,
    height: 35,
  },

  weAreTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 38 : 70),
    color: "white",
    zIndex: 1,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
  },

  HiringTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 35 : 85),
    color: theme.magenta,
    zIndex: 1,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
  },
  payloadSmallTextStyle: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(20),
    marginTop: 16,
    width: isMobile() ? "90%" : "80%",
    textAlign: isMobile() ? "center" : "left",
  },
  companyStyle: {
    textAlign: "center",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
  },
  payloadStyle: {
    fontFamily: "Jost, sans-serif",
    fontSize: getFontSizeXD(21),
    fontWeight: "bold",
    color: "white",
    letterSpacing: 2.2,
    position: "absolute",
    width: 136,
  },
  payloadContainerStyle: {
    position: "relative",
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: isMobile() ? "center" : "left",
    paddingRight: isMobile() ? "40px" : "0px",
    alignItems: "center",
    marginTop: 20,
  },
  textTakeBackground: {
    backgroundColor: theme.magenta,
    height: 25,
    width: 57,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
  },
  prendiBackground: {
    backgroundColor: theme.magenta,
    height: 25,
    width: 100,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
  },
  textPosizioniStyle: {
    zIndex: 99999,
    position: "absolute",
    whiteSpace: "nowrap",
    top: 0,
    left: 0,
  },

  footerStyle: {
    textAlign: "center",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    marginTop: 40,
    marginBottom: 20,
    zIndex: 10,
  },
  companyStyleMobile: {
    textAlign: "center",
    width: "80%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
    marginLeft: "10%",
  },
  footerStyleMobile: {
    textAlign: "center",
    width: "80%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
    marginTop: 40,
    marginBottom: 20,
    marginLeft: "10%",
  },
  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 400,
    height: 400,
    borderRadius: 200,
    position: "absolute",
    top: 20,
    right: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 250,
    height: 250,
    borderRadius: 135,
    position: "absolute",
    top: 400,
    left: -50,
  },
};

export default styles;

import theme from "../../theme";
import { getFontSizeXD, isMobile } from "../../utils";

const styles = {
  root: {
    position: isMobile() ? "relative" : "absolute",
    overflow: "hidden",
  },
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  textsContainerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    zIndex: 10,
  },
  takeASliceOfTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 52 : 70),
    color: "white",
    zIndex: 10,
    WebkitTextStrokeColor: "white",
    WebkitTextStrokeWidth: 1,
  },
  pixxaTextStyle: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 800,
    fontSize: getFontSizeXD(isMobile() ? 56 : 85),
    color: theme.magenta,
    zIndex: 10,
    WebkitTextStrokeColor: theme.magenta,
    WebkitTextStrokeWidth: 1,
  },

  bigCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #410394 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 250,
    height: 250,
    borderRadius: 135,
    position: "absolute",
    top: 20,
    left: -20,
  },
  smallCircleStyle: {
    background:
      "transparent linear-gradient(180deg, #21005C 0%, #FF005C 100%) 0% 0% no-repeat padding-box",
    opacity: 0.5,
    width: 400,
    height: 400,
    borderRadius: 200,
    position: "absolute",
    top: 400,
    right: -50,
  },
  iconsContainerStyle: {
    display: "flex",
    flexDirection: "row",
    zIndex: 10,
  },
  iconContainerStyle: {
    marginLeft: 20,
    marginRight: 20,
  },
  infoTextStyle: {
    fontFamily: "Jost, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
  },
  footerStyle: {
    textAlign: "center",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    marginTop: 22,
    marginBottom: 20,
    zIndex: 10,
  },
  companyStyle: {
    textAlign: "center",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
  },
  companyStyleMobile: {
    textAlign: "center",
    width: "80%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
    marginLeft: "10%",
  },
  certificationStyle: {
    textAlign: "center",
    width: "100%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    marginBottom: 20,
    zIndex: 10,
  },
  certificationStyleMobile: {
    textAlign: "center",
    width: "80%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
    marginLeft: "10%",
  },
  footerStyleMobile: {
    textAlign: "center",
    width: "80%",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    fontSize: getFontSizeXD(16),
    zIndex: 10,
    marginTop: 10,
    marginBottom: 20,
    marginLeft: "10%",
  },
};

export default styles;

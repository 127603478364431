import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import { positions } from "../../utils";
import AnimatedBackground from "../../components/AnimatedBackground";
import * as menuActions from "../../reducers/menu/actions";
import IntroSection from "../../sections/IntroSection";
import MenuButton from "../../components/MenuButton";
import styles from "./styles";
import MenuPage from "../MenuPage";
import WelcomeSection from "../../sections/WelcomeSection";
import AboutUsSection from "../../sections/AboutUsSection";
import PixxerSection from "../../sections/PixxerSection";
import SkillSection from "../../sections/SkillSection";
import LmcSection from "../../sections/LmcSection";
import ContactUsSection from "../../sections/ContactUsSection";
import HiringSection from "../../sections/HiringSection";

class LandingPageMobile extends Component {
  state = {
    deviceHeight: 0,
    deviceWidth: 0,
    orientation: "",
  };

  componentWillMount() {
    if (window.orientation) {
      this.setState({
        orientation:
          window.orientation === 0 || window.orientation === 180
            ? "portrait"
            : "landscape",
      });
    }
  }

  componentDidMount() {
    this.setState({
      deviceHeight:
        window.innerHeight < 812
          ? window.innerHeight * 1.5
          : window.innerHeight,
      deviceWidth: window.innerWidth,
    });

    setTimeout(() => {
      if (window.location.hash) {
        const hashUrl = window.location.hash;
        if (hashUrl.includes("workWithUs") && hashUrl.length > 10) {
          const posizione = document.getElementById(hashUrl.slice(11));
          if (posizione) {
            const scrollPosizioni = document.getElementById("hiringScroll");
            scrollPosizioni.scrollTo(posizione.offsetLeft, 0);
            const el = document.getElementById("HiringSection");
            window.scrollTo(0, el.offsetTop);
          } else if (hashUrl === "#workWithUs") {
            const el = document.getElementById("HiringSection");
            window.scrollTo(0, el.offsetTop);
          } else {
            let noHashURL = window.location.href.replace(/#.*$/, "");
            window.history.replaceState("", document.title, noHashURL);
          }
        } else {
          const el = document.getElementById(hashUrl.slice(1) + "Section");
          if (el) {
            window.scrollTo(0, el.offsetTop);
          } else {
            let noHashURL = window.location.href.replace(/#.*$/, "");
            window.history.replaceState("", document.title, noHashURL);
          }
        }
      }
    }, 100);
  }

  setOrientation = (orientation) => {
    setTimeout(() => {
      let introHeight;
      if (orientation === "landscape") {
        introHeight = window.innerWidth;
      } else if (orientation === "portrait") {
        introHeight = window.innerHeight;
        this.toggleMenu();
      }
      this.setState({ orientation, introHeight });
    }, 100);
  };

  toggleMenu = (isOpen) => {
    this.props.toggleMenu(isOpen);
  };
  render() {
    const { windowWidth, windowHeight, isOpen } = this.props;
    const { root } = styles;
    const { deviceHeight, deviceWidth, orientation, introHeight } = this.state;
    let index = 0;
    const hashUrl = window.location.hash;

    if (hashUrl.includes("workWithUs") && hashUrl.length > 11) {
      const hashPosition = hashUrl.slice(11);
      index = positions.findIndex((position) => {
        return position.id === hashPosition;
      });
      if (index === -1) {
        index = 0;
      }
    }

    return (
      <Fragment>
        <DeviceOrientation onOrientationChange={this.setOrientation}>
          <Orientation orientation="portrait" />
        </DeviceOrientation>
        {orientation === "portrait" && (
          <Fragment>
            <AnimatedBackground
              windowWidth={deviceWidth}
              windowHeight={introHeight}
            />
            <div
              className="scrollContainer"
              style={Object.assign({}, root, {
                width: windowWidth,
                height: deviceHeight,
              })}
            >
              <MenuPage
                isOpen={isOpen}
                windowWidth={deviceWidth}
                windowHeight={deviceHeight}
                toggleMenu={this.toggleMenu}
                loadSection={this.scrollSection}
              />
              <div>
                <IntroSection
                  windowWidth={deviceWidth}
                  windowHeight={windowHeight}
                />
                <WelcomeSection
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />
                <LmcSection
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />
                <AboutUsSection
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />
                <PixxerSection
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />
                <SkillSection
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />
                <HiringSection
                  index={index}
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />
                <ContactUsSection
                  windowWidth={deviceWidth}
                  windowHeight={deviceHeight}
                />

                <MenuButton onClick={this.toggleMenu} />
              </div>
            </div>
          </Fragment>
        )}
        {orientation === "landscape" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: window.innerWidth,
              height: window.innerHeight,
              overflow: "hidden",
            }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Montserrat", color: "white" }}
            >
              Please rotate your device in portrait mode
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ dimensions, menu }) => {
  return {
    windowWidth: dimensions.windowWidth,
    windowHeight: dimensions.windowHeight,
    isOpen: menu.isOpen,
  };
};

export default compose(
  connect(mapStateToProps, menuActions),
  withTranslation("landingPage")
)(LandingPageMobile);

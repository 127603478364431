import React, { Component } from "react";
import { Expo, TimelineMax, Power2, TweenLite, Power0, Power1 } from "gsap";
import Lottie from "react-lottie";
import config from "./../../config";
import style from "./style";
import AnimatedPixels from "../AnimatedPixels";
import Plane from "../Plane";
import { isMobile } from "../../utils";
import AnimatedCircles from "../AnimatedCircles";
import circlesAnimation from "../../animationsAE/circlesAnimation";
import explosionAnimation from "./../../animationsAE/explosionAnimation.json";
import explosionMobileAnimation from "./../../animationsAE/explosionMobileAnimation.json";
import pixelBackgroundAnimation from "./../../animationsAE/pixelBackgroundAnimation.json";
import pixelBackgroundMobileAnimation from "./../../animationsAE/pixelBackgroundMobileAnimation.json";

class AnimatedBackground extends Component {
  state = {
    isExplosion: true
  };
  componentDidMount() {
    if (!isMobile()) {
      window.addEventListener("resize", () => {
        this.setParallax();
      });
    }
    // this.explosion();
  }

  explosion = () => {
    const { windowHeight, windowWidth } = this.props;
    const { EXPLOSION_DELAY, EXPLOSION_DURATION, TRIGGER_DURATION } = config;

    let startX = windowWidth / 2;
    let startY = windowHeight / 2;
    let startXCubes = windowWidth / 2;
    let startYCubes = windowHeight / 2;
    let dur = EXPLOSION_DURATION;
    let triggerDuration = TRIGGER_DURATION;
    let delay = EXPLOSION_DELAY;

    let tl = new TimelineMax({
      delay,
      paused: true
    });
    tl.to(
      ".triggerCubesContainer",
      triggerDuration,
      { rotationZ: 360 * 2, ease: Expo.easeIn },
      0
    );
    tl.to(
      ".leftTriggerSquare, .rightTriggerSquare",
      triggerDuration,
      {
        left: windowWidth / 2 - 5
      },
      0
    );
    tl.to(
      ".leftTriggerSquare, .rightTriggerSquare",
      0.2,
      { opacity: 0 },
      triggerDuration - 0.2
    );
    tl.from(
      ".squares rect",
      dur,
      { attr: { x: startX, y: startY }, ease: Power2.easeOut },
      triggerDuration
    );

    tl.from(
      ".squares2 rect",
      dur,
      { attr: { x: startX, y: startY }, ease: Power2.easeOut },
      triggerDuration
    );
    tl.to(".cubesContainer", 0, { opacity: 1 }, triggerDuration + 0.1);
    tl.to(".squaresContainer", 0, { opacity: 1 }, triggerDuration + 0.1);
    tl.from(
      ".cube",
      dur,
      {
        ease: Power0.easeOut,
        left: startXCubes,
        top: startYCubes
      },
      triggerDuration
    );
    tl.play();
    tl.eventCallback("onComplete", () => {
      if (!isMobile()) {
        this.setParallax();
      }
    });
  };

  setParallax = () => {
    let request = null;
    let mouse = { x: 0, y: 0 };

    window.addEventListener("mousemove", event => {
      mouse.x = event.pageX;
      mouse.y = event.pageY;
      cancelAnimationFrame(request);
      request = requestAnimationFrame(update);
    });

    function update() {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      let dx = mouse.x;
      let dy = mouse.y;
      let kMovementCubes = config.K_PARALLAX_CUBES;
      TweenLite.to("#svg1", 1, {
        left: -dx / 10 + windowWidth / 2 / 10,
        top: -dy / 10 + windowWidth / 2 / 10,
        ease: Power2.easeOut
      });
      TweenLite.to("#svg2", 1, {
        left: dx / 10 - windowWidth / 2 / 10,
        top: dy / 10 - windowWidth / 2 / 10,
        ease: Power2.easeOut
      });
      TweenLite.to(".cubesContainer", 1, {
        ease: Power1.easeOut,
        translateX: dx / kMovementCubes - windowWidth / 2 / kMovementCubes,
        translateY: dy / kMovementCubes - windowHeight / 2 / kMovementCubes
      });
    }
  };

  renderBackgroundSeparators = () => {
    const { windowWidth, windowHeight } = this.props;
    let xPositions = [20, 40, 60, 80];
    return xPositions.map((val, i) => {
      return (
        <div
          key={i}
          style={{
            backgroundColor: "white",
            position: "absolute",
            left: (windowWidth * val) / 100,
            width: 1,
            height: windowHeight * 2,
            opacity: 0.2,
            top: 0
          }}
        />
      );
    });
  };

  render() {
    const {
      backgroundContainer,
      triggerCubeStyle,
      triggerCubesContainer
    } = style;
    const { isExplosion } = this.state;
    const { windowWidth, windowHeight } = this.props;

    return (
      <div
        id="container"
        style={Object.assign({}, backgroundContainer, {
          width: windowWidth,
          height: windowHeight
        })}
      >
        {isExplosion && (
          <Lottie
            width={isMobile() ? "auto" : windowWidth}
            height={isMobile() ? windowHeight : "auto"}
            options={{
              animationData: isMobile()
                ? explosionMobileAnimation
                : explosionAnimation,
              loop: false
            }}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => this.setState({ isExplosion: false })
              }
            ]}
          />
        )}
        {!isExplosion && (
          <Lottie
            width={isMobile() ? "auto" : windowWidth}
            height={isMobile() ? windowHeight : "auto"}
            options={{
              animationData: isMobile()
                ? pixelBackgroundMobileAnimation
                : pixelBackgroundAnimation,
              loop: true
            }}
          />
        )}
        {!isMobile() && <AnimatedCircles />}
        {isMobile() && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: 0
            }}
          >
            <Lottie options={{ animationData: circlesAnimation }} />
          </div>
        )}
        <Plane
          id="plane1"
          rotationRadius={windowWidth / 2}
          duration={5}
          clockwise
        />
        <Plane id="plane2" rotationRadius={windowWidth / 4} duration={3} />
        {this.renderBackgroundSeparators()}
        <AnimatedPixels />
        <AnimatedPixels front />
        <div
          className="triggerCubesContainer"
          style={Object.assign({}, triggerCubesContainer, {
            width: windowWidth,
            height: windowHeight
          })}
        >
          <div
            className="leftTriggerSquare"
            style={Object.assign(
              {},
              { left: -200, top: windowHeight / 2 },
              triggerCubeStyle
            )}
          />
          <div
            className="rightTriggerSquare"
            style={Object.assign(
              {},
              { left: windowWidth + 200, top: windowHeight / 2 },
              triggerCubeStyle
            )}
          />
        </div>
      </div>
    );
  }
}

export default AnimatedBackground;

const icons = {
  facebook: require("./facebook-icon@2x.png"),
  instagram: require("./instagram-icon@2x.png"),
  linkedin: require("./linkedin-icon@2x.png"),
  facebookSmall: require("./facebook_small@2x.png"),
  instagramSmall: require("./instagram_small@2x.png"),
  linkedinSmall: require("./linkedin_small@2x.png"),
  sendMail: require("./sent-mail.png"),
  sendMailWhite: require("./sent-mail_white.png"),
  rightArrow: require("./rightArrow.png"),
  rightArrowWhite: require("./rightArrow_white.png"),
  menuOpen: require("./menu_open.png"),
  menuClose: require("./menu_close.png"),
  iconaSocialBlue: require("./iconaSocialBlue.png"),
  iconaSocialWhite: require("./iconaSocialWhite.png"),
  iconaFullStackDevBlue: require("./iconaFullStackDevBlue.png"),
  iconaFullStackDevwhite: require("./iconaFullStackDevWhite.png"),
  iconaDataEntryBlue: require("./iconaDataEntryBlue.png"),
  iconaDataEntryWhite: require("./iconaDataEntryWhite.png"),
  itIcon: require("./itIcon.png"),
  ukIcon: require("./ukIcon.png"),
};

export default icons;

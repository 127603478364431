import $ from "jquery";

export const isMobile = () => {
  let isMobile = false;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};

export const getComputedScaleXY = (el) => {
  if (!window.getComputedStyle || !el) return false;

  const style = getComputedStyle(el);
  const transform =
    style.transform || style.webkitTransform || style.mozTransform;

  let mat = transform.match(/^matrix3d\((.+)\)$/);

  if (mat) return parseFloat(mat[1].split(", ")[13]);

  mat = transform.match(/^matrix\((.+)\)$/);

  const data = {};

  data.scale = mat ? parseFloat(mat[1].split(", ")[3]) : 0;

  data.x = mat ? parseFloat(mat[1].split(", ")[4]) : 0;
  data.y = mat ? parseFloat(mat[1].split(", ")[5]) : 0;

  data.xPercent = data.x === 0 ? 0 : data.x / (el.offsetWidth / 100);
  data.yPercent = data.y === 0 ? 0 : data.y / (el.offsetHeight / 100);

  return data;
};

export const generateRandomFromRange = (max, min, isFloor) => {
  let randomValue = Math.random() * (+max - +min) + +min;
  let randomFromRange = isFloor ? Math.floor(randomValue) : randomValue;
  return randomFromRange;
};

export const randomRotationDirection = () => {
  let rotations = ["-360", "360"];
  let randomIndexRotation = Math.floor(Math.random() * rotations.length);
  return Number(rotations[randomIndexRotation]);
};

export const preventFromScrolling = () => {
  let scrollPosition = [
    document.documentElement.scrollLeft || document.body.scrollLeft,
    document.documentElement.scrollTop || document.body.scrollTop,
  ];
  let html = $("html"); // it would make more sense to apply this to body, but IE7 won't have that
  html.data("scroll-position", scrollPosition);
  html.data("previous-overflow", html.css("overflow"));
  html.css("overflow", "hidden");
  window.scrollTo(scrollPosition[0], scrollPosition[1]);
};

export const restoreScrolling = () => {
  let html = $("html");
  let scrollPosition = html.data("scroll-position");
  html.css("overflow", html.data("previous-overflow"));
  window.scrollTo(scrollPosition[0], scrollPosition[1]);
};

export const slicesPositions = {
  officeEnvironment: {
    initX: 153.118,
    initY: 111.211,
    x: 145,
    y: 80,
  },
  webAppDev: {
    initX: 107.794,
    initY: 193.703,
    x: 77.794,
    y: 193.703,
  },
  graphicAndVideo: {
    initX: 329.341,
    initY: 326.498,
    x: 357,
    y: 346,
  },
  socialMediaMarketing: {
    initX: 151.624,
    initY: 326.498,
    x: 142,
    y: 356,
  },
  userExperience: {
    initX: 329.341,
    initY: 122.487,
    x: 355,
    y: 105,
  },
};

export const slicesPositionsMobile = {
  officeEnvironment: {
    initX: 28.088,
    initY: 0,
    x: 22,
    y: -20,
  },
  webAppDev: {
    initX: 0,
    initY: 51.121,
    x: -14,
    y: 51.121,
  },
  graphicAndVideo: {
    initX: 137.296,
    initY: 133.417,
    x: 150,
    y: 143,
  },
  socialMediaMarketing: {
    initX: 27.162,
    initY: 133.417,
    x: 23,
    y: 147,
  },
  userExperience: {
    initX: 137.296,
    initY: 6.967,
    x: 150,
    y: -3,
  },
};

export const circlesPositions = {
  IntroSection: {
    leftBig: 0,
    leftSmall: 0,
    topBig: 0,
    topSmall: 0,
  },
  WelcomeSection: {
    leftBig: -100,
    leftSmall: 1050,
    topBig: -1740,
    topSmall: 300,
  },
  AboutUsSection: {
    leftBig: -1000,
    leftSmall: 1050,
    topBig: 500,
    topSmall: -800,
  },
  PixxerSection: {
    leftBig: -1000,
    leftSmall: -2000,
    topBig: -1000,
    topSmall: -2000,
  },
  SkillSection: {
    leftBig: 500,
    leftSmall: -2000,
    topBig: -1000,
    topSmall: -2000,
  },
  LmcSection: {
    leftBig: -3000,
    leftSmall: -500,
    topBig: -3000,
    topSmall: -100,
  },
  ContactUsSection: {
    leftBig: -3000,
    leftSmall: 260,
    topBig: -3000,
    topSmall: 600,
  },
  HiringSection: {
    leftBig: -1000,
    leftSmall: -2000,
    topBig: -1000,
    topSmall: -2000,
  },
};

export const getFontSizeXD = (num) => {
  return num / 1.33;
};

export const positions = [
  {
    id: "fullStackDeveloper",
    title: "Full Stack Developer",
    subTitle: "Junior - Middle - Senior",
    heading: "Requisiti",
    content:
      "Vogliamo inserire nel team una figura di Full Stack Developer al quale affidare la gestione dell'app e delle piattaforme gestionali, intesa come sviluppo, manutenzione e integrazione con gli altri processi aziendali. La risorsa lavorerà a stretto contatto con la CEO e in team con gli UX Designers e gli altri Dev.",
    requirements: [
      {
        contenuto:
          "Conoscenza/Esperienza Programmazione Web (PHP, HTML5, CSS3 e Javascript)",
      },
      {
        contenuto: "Conoscenza/Esperienza framework React JS e React Native",
      },
      {
        contenuto: "Conoscenza/Esperienza framework Laravel",
      },
      {
        contenuto: "Database (MySQL, MariaDB, MongoDB…) e modellazione dati",
      },
      {
        contenuto: "Sviluppo di REST API",
      },
      {
        contenuto: "Approccio DevOps",
      },
    ],
    icon: require("./assets/images/icons/iconaFullStackDevBlue.png"),
    iconSelected: require("./assets/images/icons/iconaFullStackDevWhite.png"),
  },
];

import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import Lottie from "react-lottie";
import mouseAnimation from "./../../animationsAE/mouseAnimation";
import styles from "./styles";

class AnimatedScrollIcon extends Component {
  render() {
    const { t } = this.props;
    const {
      scrollDownTextStyle,
      animationContainerStyle,
      animatedScrollIcon
    } = styles;
    return (
      <Fragment>
        <div id="animatedScrollIconContainer" style={animationContainerStyle}>
          <div style={animatedScrollIcon}>
            <Lottie options={{ animationData: mouseAnimation, loop: true }} />
          </div>
        </div>
        <div id="animatedScrollIconText" style={scrollDownTextStyle}>
          {t("scrollDown")}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("landingPage")(AnimatedScrollIcon);
